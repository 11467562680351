import * as React from "react";
import { Snackbar, createMuiTheme, MuiThemeProvider } from "@material-ui/core";

interface INotificationProps {
    open: boolean;
    message: string;
    onClose: Function;
}

interface INotificationState {
    open: boolean;
}

const theme = createMuiTheme({
    overrides: {
        MuiSnackbarContent: {
            root: { "background-color": "#9b9b9b" },
            message: { "font-family": "ToyotaText" }
        }
    }
});

export class Notification extends React.Component<INotificationProps, INotificationState> {
    constructor(props) {
        super(props);

        this.state = { open: this.props.open };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.setState({ open: this.props.open });
        }
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}><Snackbar
                className="notification"
                onClick={this.onClick}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={3000}
                onClose={this.onClose}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                message={<span id="message-id">{this.props.message}</span>}
            />
            </MuiThemeProvider>
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onClick = event => {
        event.stopPropagation();
        this.props.onClose();
    };
}
