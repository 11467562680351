import React from 'react';
import PRListHeader from "../../PageContent/PRListHeader/PRListHeader";
import PRContentCard from "../../PageContent/PRContentCard/PRContentCard";
import './PRContentList.scss';

export interface PRContentListProps {
    data:{
        Header?: string;
        Items?: Array<{
            Id:string;
            Image: string;
            Title: string;
            Url: string;
        }>;
    }    
}

export default class PRContentList extends React.Component<PRContentListProps, {}> {
    constructor(props) {
        super(props);
    }

    render() { 
        let cards = null;
        if (this.props.data.Items != null)
        {
            cards = this.props.data.Items.map((item, $index) => {
                return (
                    <PRContentCard key={$index} data={item}/>
                );
            });
        }
        else
        {
            cards = this.props.children;
        }
        return (
            <div className="content-list">
                <PRListHeader header={this.props.data.Header}></PRListHeader>
                {cards}
            </div>
        );
    }
}