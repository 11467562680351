import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { PRIcon } from "../../../../PRIcon/PRIcon";
import "./PRDropdown.scss";
import { ArrowDownIcon } from "../../../../PRIcon/Icons";
import { InputLabel, Input } from "@material-ui/core";

export interface PRDropdownProps {
  required?: boolean;
  id: any;
  onChange: Function;
  filter: {
    Values?: Array<{
      Value: any;
      Name: string;
      Selected: boolean;
    }>;
  };
  theme?: string;
  error?: boolean;
  label?: string;
  includeNone?: boolean;
  includeAll?: boolean;
}

interface IPRTextFieldState {
  error: boolean;
  selected: string;
}

class PRDropdown extends React.Component<PRDropdownProps, IPRTextFieldState> {
  constructor(props) {
    super(props);

    this.state = {
      error: this.props.error,
      selected: this.getSelectedValue(this.props.filter.Values)
    };
  }

  handleFilterChange = event => {
    let error = (!event.target.value && this.props.required);
    this.setState({ error: error, selected: event.target.value });
    this.props.onChange(this.props.id, event.target.name, event.target.value);
  };

  componentDidUpdate(prevProps) {
    // If the selected value has been changed by the parent, update the state.
    let prevSelectedValue = this.getSelectedValue(prevProps.filter.Values);
    let newSelectedValue = this.getSelectedValue(this.props.filter.Values);
    if (prevSelectedValue !== newSelectedValue) {
      this.setState({ selected: newSelectedValue });
    }
    // If the error value has been changed by the parent, update the state.
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
  }

  getSelectedValue = (values) => {
    let selectedValues = values.filter(value => value.Selected);
    return selectedValues.length ? selectedValues[0].Value : '';
  }

  render() {
    let className = "pr-dropdown " + (this.props.required ? " required " : "") + this.props.theme;
    return (
      <div className={className}>
        {this.props.required && <span className="required">*</span>}
        <FormControl margin="normal"
          classes={{ root: "dropdown-form-control" }}
          error={this.state.error}
        >
          {this.props.label ?
            <InputLabel
              FormLabelClasses={{ root: "dropdown-label", focused: "focused" }}
              htmlFor="dropdown">{this.props.label}</InputLabel> : ""}
          <Select
            error={this.state.error}
            value={this.state.selected}
            onChange={this.handleFilterChange}
            input={<Input classes={{ root: "input-root", underline: "input-underline", error: "input-error" }} name={"filter-" + this.props.id} id="dropdown" />}
            IconComponent={props => (
              <PRIcon {...props} className={"material-icons " + props.className}><ArrowDownIcon /></PRIcon>
            )}
            classes={{ root: "dropdown-root", select: "dropdown-select", icon: "dropdown-icon" }}
          >
            {this.props.includeNone &&
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            }
            {this.props.includeAll &&
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
            }
            {this.props.filter.Values.map((item, $index) => {
              return (
                <MenuItem key={$index} value={item.Value}>
                  {item.Name}
                </MenuItem>
              );
            })}
          </Select>
          {this.state.error && (
            <span className="error-text">
              {!this.state.selected ? "Required" : "Invalid"}
            </span>
          )}

        </FormControl>
      </div>
    );
  }
}

export default PRDropdown;
