import React from 'react';
import './PRListHeader.scss';

export interface PRListHeaderProps {
    header: string;
}

export default class PRListHeader extends React.Component<PRListHeaderProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="list-header">
                <div className="list-header-label">
                    {this.props.header}
                </div>
                {this.props.children && <div>
                    {this.props.children}
                </div>}
            </div>
        );
    }
}