import React from "react";
import "./PRFooter.scss";
import withWidth from "@material-ui/core/withWidth";
import Placeholder from "../../../js/extensions/Placeholder";
import PRLink from "../PRLink/PRLink";

export interface PRFooterProps {
  data: {
    BottomLinks: Array<{
      Url: string;
      Label: string;
      Icon: string;
    }>;
  };
  placeholder?: boolean;
  isSitecore?: boolean;
  width?: string;
}

class PRFooter extends React.Component<PRFooterProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    let className = "pr-footer " + this.props.width;
    return (
      <div className={className}>
        <div className="row pr-footer-container">
          <div className="footer-content">
            <Placeholder
              placeholderKey={"tp-rowContainer"}
              isDynamic={true}
              placeholder={this.props.placeholder}
            >
              {this.props.children}
            </Placeholder>
            <div className="terms">
              {this.props.data.BottomLinks.map((linkItem, $index) => {
                if ($index < this.props.data.BottomLinks.length - 1) {
                  return (
                    <div
                      className="container"
                      key={"PRFooter-term-" + $index}
                    >
                      <PRLink data={linkItem} />
                      <span className="divider">|</span>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="container"
                      key={"PRFooter-term-" + $index}
                    >
                      <PRLink data={linkItem} />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withWidth()(PRFooter);
