import React from 'react';
import "./PRMemberCard.scss";
import PRMemberCard from './PRMemberCard';

export interface PRMemberCardListProps {
    data?: {
        Items?: Array<{
            Image: string;
            Name: string;
            Position: string;
            Url:string;
            }>;
    };
    isSitecore?:boolean;
}

export default class PRMemberCardList extends React.Component<PRMemberCardListProps,{}> {
    constructor(props) {
        super(props);
    }

    render() {
        let memberList = [];
        if (this.props.isSitecore)
        {
            this.props.data.Items.map((child, $index) => {
                memberList.push(<PRMemberCard data={child} key={`card-${$index}`}></PRMemberCard>);
            });

        }
        else{
            React.Children.map(this.props.children, (child, $index) => {
                memberList.push(child);
            });
        }
        return(
            <div className="pr-member-card-list">
                <div className="pr-member-card-list-container">
                    {memberList}
                </div>
            </div>
        );
    }
}