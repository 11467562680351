import React from "react";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import { DateTimePicker } from "material-ui-pickers";
import "./Date.scss";
import { CalendarIcon } from "../../PRIcon/Icons"
import { PRIcon } from "../../PRIcon/PRIcon"

interface IDateTimeProps {
  label?: string;
  name?: string;
  onChange?: Function;
  selectedDateTime?: string;
  clearable?: boolean;
  disablePast?: boolean;
}

interface IDateTimeState {
  selectedDateTime?: any;
}

export class DateTime extends React.Component<IDateTimeProps, IDateTimeState> {
  private calendarRef: any = null;

  constructor(props: IDateTimeProps) {
    super(props);

    this.state = {
      selectedDateTime: this.convertNullValuesToNull(this.props.selectedDateTime)
    };

    this.calendarRef = React.createRef();
  }

  componentDidUpdate(prevProps: IDateTimeProps) {
    // If the selectedDateTime has been changed by the parent, update the state.
    if (prevProps.selectedDateTime !== this.props.selectedDateTime) {
      this.setState({ selectedDateTime: this.convertNullValuesToNull(this.props.selectedDateTime) });
    }
  }

  handleDateTimeChange = date => {
    this.props.onChange(date ? date.format() : null, this.props.name);
    this.setState({ selectedDateTime: date });
  };

  convertNullValuesToNull = (value) => {
    return (!value || value === "null") ? null : value;
  }

  render() {
    const { selectedDateTime } = this.state;
    const now = new Date();
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="pr-date">
          <DateTimePicker
            keyboard
            label={this.props.label}
            format="DD/MM/YYYY HH:mm"
            // handle clearing outside => pass plain array if you are not controlling value outside
            // needs to match the format above
            mask={value =>
              value
                ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]
                : []
            }
            value={selectedDateTime}
            onChange={this.handleDateTimeChange}
            disableOpenOnEnter
            animateYearScrolling={false}
            className="datepicker-root"
            InputLabelProps={{ classes: { root: "input-label-root" } }}
            InputProps={{ classes: { root: "input-root", input: "input-input" },  name: this.props.name }}
            keyboardIcon={<PRIcon className="calendar-icon"><CalendarIcon /></PRIcon>}
            InputAdornmentProps={{ classes: { root: "input-adornment-root" } }}
            clearable={this.props.clearable}
            disablePast={this.props.disablePast}
            minDate={now}
            minDateMessage="Must be in the future"
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}
