import React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import PRDialog from "../PRDialog/PRDialog";
import Form from "../Form/Form";
import PRTextField from "../Form/Fields/PRTextField/PRTextField";
import PRCheckbox from "../Form/Fields/PRCheckbox/PRCheckbox";
import PRButton from "../PRButton/PRButton";
import "./Subscribe.scss";
import { NewsletterIcon } from "../../PRIcon/Icons";
import { RequestHandler } from "../../../services/requestHandler";

interface ISubscribeProps {
  data: {
    HeaderLabel: string;
    HeaderIcon?: string;
    ApiUrl?: string;
    PrimaryBtnLabel?: string;
    SecondaryBtnLabel?: string;
    TermsAndConditionsLink: string;
  };
}

interface ISubscribeState {
  openDialog: boolean;
  subscribed: boolean;
}

export default class Subscribe extends React.Component<ISubscribeProps, ISubscribeState> {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      subscribed: false
    };
  }

  onSubmit = async data => {
    let url = window.location.protocol +
      "//" +
      window.location.host +
      this.props.data.ApiUrl;
    let requestHandler = new RequestHandler(url);
    let result = await requestHandler.post("", JSON.stringify({
      Name: data.Name,
      EmailAddress: data.EmailAddress,
      Company: data.Company,
      Role: data.Role
    }));
    if (result.success) {
      this.setState({ subscribed: true });
    }
  };

  render() {
    return (
      <div>
        <div className="subscribe-button"
          onClick={() => {
            this.setState({ openDialog: true });
          }}
        >
          <PRIcon>
            <NewsletterIcon />
          </PRIcon>
        </div>
        <PRDialog
          open={this.state.openDialog}
          onClose={() => {
            this.setState({ openDialog: false, subscribed: false });
          }}
        >
          <div className="subscribe-dialog-header" key="header">
            <PRIcon>
              <NewsletterIcon />
            </PRIcon>
            <h3
              dangerouslySetInnerHTML={{ __html: this.props.data.HeaderLabel }}
            />
          </div>
          <div key="content" className="subscribe-dialog-content">
            {!this.state.subscribed && (
              <Form
                data={{
                  primaryBtnLabel: this.props.data.PrimaryBtnLabel,
                  secondaryBtnLabel: this.props.data.SecondaryBtnLabel
                }}
                onCancel={() => {
                  this.setState({ openDialog: false });
                }}
                onSubmit={this.onSubmit}
              >
                <PRTextField required label="Name" name="Name" />
                <PRTextField
                  required
                  label="Email"
                  name="EmailAddress"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                />
                <PRTextField label="Company" name="Company" />
                <PRTextField label="Role" name="Role" />
                <PRCheckbox options={{ theme: "dark" }} required name="Terms">
                  <span>
                    Yes, I agree to the{" "}
                    <a href={this.props.data.TermsAndConditionsLink}>
                      terms and conditions
                    </a>
                  </span>
                </PRCheckbox>
              </Form>
            )}

            {this.state.subscribed && (
              <div className="subscribe-success">
                <p>{"You'll now start receiving alerts from us."}</p>
                <PRButton
                  data={{ type: "secondary" }}
                  onClick={() => {
                    this.setState({ openDialog: false, subscribed: false });
                  }}
                >
                  Close
                </PRButton>
              </div>
            )}
          </div>
        </PRDialog>
      </div>
    );
  }
}
