import React from 'react';
import "./PRImageCard.scss";

export interface PRImageCardProps {
    data: {
        ImageUrl: string;
        Description: string;
    }
}

export default class PRImageCard extends React.Component<PRImageCardProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="pr-image-card">
                <div className="pr-image-container" dangerouslySetInnerHTML={{__html: this.props.data.ImageUrl}}></div>
                <div className="pr-image-description-container" dangerouslySetInnerHTML={{__html: this.props.data.Description}}></div>
            </div>
        );
    }
}