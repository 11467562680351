import React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import "./PRImageCopy.scss";
import { WebIcon } from "../../PRIcon/Icons";

export interface PRImageCopyProps {
  data: {
    Title: string;
    Copy: string;
    LinkUrl?: string;
    ImageUrl: string;
  };

  options?: {
    Layout: string;
  };
}

export default class PRImageCopy extends React.Component<PRImageCopyProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    let className =
      "pr-image-copy-container " +
      (this.props.options ? this.props.options.Layout : "");
    return (
      <div>
        <div className="pr-image-copy">
          <div className={className}>
            <div className="pr-copy-container">
              <div
                dangerouslySetInnerHTML={{ __html: this.props.data.Title }}
                className="title"
              />
              <div dangerouslySetInnerHTML={{ __html: this.props.data.Copy }} />
              <div className="link-container">
                <PRIcon>
                  <WebIcon />
                </PRIcon>
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.data.LinkUrl }}
                />
              </div>
            </div>
            <div
              className="pr-image-container"
              dangerouslySetInnerHTML={{ __html: this.props.data.ImageUrl }}
            />
          </div>
        </div>
      </div>
    );
  }
}
