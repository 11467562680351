import React from "react";
import "./CardActions.scss";
import PRAddToCart from "../../PageContent/icons/PRAddToCart";
import DownloadItem from "../../PageContent/icons/DownloadItem";
import PRCheckbox from "../../PageContent/Form/Fields/PRCheckbox/PRCheckbox";
import { DeleteItem } from "./DeleteItem/DeleteItem";
import { EditItem } from "./EditItem/EditItem";

interface ICardActionsProps {
  actions: {
    select?: boolean;
    addToCart?: boolean;
    download?: boolean;
    edit?: boolean;
    delete?: boolean;
  };
  item: {
    Id?: string;
    Image?: string;
    Title?: string;
    Copy?: string;
    Type?: string;
    Selected?: boolean;
  };
  onSelectionChange?: Function;
  onEdit?: Function;
  onDelete?: Function;
}

interface ICardActionsState {
  selected: boolean;
  deleteConfirmationOpen: boolean;
}

export class CardActions extends React.Component<ICardActionsProps, ICardActionsState> {
  constructor(props: ICardActionsProps) {
    super(props);

    this.state = {
      selected: this.props.item.Selected,
      deleteConfirmationOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.item.Selected !== prevProps.item.Selected) {
      this.setState({ selected: this.props.item.Selected });
    }
  }

  render() {
    return (
      <div className="card-actions">
        {this.props.actions.addToCart && (
          <PRAddToCart
            className="card-action-item"
            cartItem={{
              id: this.props.item.Id,
              title: this.props.item.Title,
              type: this.props.item.Type
            }}
          />
        )}
        {this.props.actions.download && (
          <DownloadItem
            className="card-action-item"
            itemId={this.props.item.Id}
          />
        )}
        {this.props.actions.select && (
          <div className="card-action-item checkbox">
            <PRCheckbox
              checked={this.state.selected}
              options={{ theme: "dark" }}
              onChange={(value: boolean) => {
                this.setState({ selected: value });
                this.props.onSelectionChange(
                  value,
                  {
                    Id: this.props.item.Id,
                    Url: this.props.item.Image,
                    Title: this.props.item.Title,
                    ShortDescription: this.props.item.Copy
                  }
                );
              }}
            />
          </div>
        )}
        {this.props.actions.edit && (
          <EditItem itemId={this.props.item.Id} itemType={this.props.item.Type} onEdit={this.props.onEdit} />
        )}
        {this.props.actions.delete && (
          <DeleteItem itemId={this.props.item.Id} itemType={this.props.item.Type} onDelete={this.props.onDelete} />
        )}
      </div>
    );
  }
}
