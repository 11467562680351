import React from "react";
import PRButton from "../../PageContent/PRButton/PRButton";
import { PRIcon } from "../../PRIcon/PRIcon";
import LocationIcon from "../../../assets/location.svg";
import "./PREventCard.scss";

export interface PREventCardProps {
  data: {
    Image?: string;
    Title?: string;
    Date?: string;
    Locationlatitude: string;
    Locationlongitude: string;
    Copy: string;
    LinkUrl: string;
  };
}

export default class PREventCard extends React.Component<PREventCardProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    let mapsUrl =
      "https://www.google.com/maps/search/?api=1&query=" +
      (this.props.data.Locationlatitude && this.props.data.Locationlongitude
        ? this.props.data.Locationlatitude +
          "," +
          this.props.data.Locationlongitude
        : "");
    return (
      <div className="pr-event-card">
        <div className="pr-event-image-container">
          <img src={this.props.data.Image} />
        </div>
        <div className="pr-event-content-container">
          <div>
            <div
              className="event-date"
              dangerouslySetInnerHTML={{ __html: this.props.data.Date }}
            />
            <h3
              className="event-title"
              dangerouslySetInnerHTML={{ __html: this.props.data.Title }}
            />
            {this.props.data.Copy && (
              <div className="event-location">
                <PRIcon>
                  <LocationIcon />
                </PRIcon>
                <a href={encodeURI(mapsUrl)}>{this.props.data.Copy}</a>
              </div>
            )}
          </div>
          <PRButton
            data={{ type: "tertiary", link: this.props.data.LinkUrl }}
            className="event-link"
          >
            Find out More
          </PRButton>
        </div>
      </div>
    );
  }
}
