import React from "react";
import { AutoComplete } from "../../../AutoComplete/Autocomplete";
import PRDialog from "../../../PRDialog/PRDialog";
import Form from "../../Form";
import PRTextField from "../PRTextField/PRTextField";
import { PRIcon } from "../../../../PRIcon/PRIcon";
import "./Contact.scss";
import Constants from "../../../../constants";
import { PenIcon, PlusIcon } from "../../../../PRIcon/Icons";
import { PressRoomApi } from "../../../../../services/pressRoomApi";
import FileDrop from "../FileDrop/FileDrop";

interface IContactFieldProps {
    onChange: Function;
    selectedContactId: any;
    required?: boolean;
    error?: boolean;
}

interface IcontactFieldState {
    openContactDialog: boolean;
    selectedContact: any;
    contacts: Array<any>;
    error: boolean;
}

export class ContactField extends React.Component<IContactFieldProps, IcontactFieldState> {
    constructor(props) {
        super(props);

        this.state = {
            openContactDialog: false,
            selectedContact: null,
            contacts: [],
            error: this.props.error
        };
    }

    async componentDidMount() {
        this.getContacts();
    }

    componentDidUpdate(prevProps) {
        // If the error value has been changed by the parent, update the state.
        if (prevProps.error !== this.props.error) {
            this.setState({ error: this.props.error });
        }
    }

    onAddContact = async (data: any) => {
        let api = new PressRoomApi();
        let result = await api.saveContact(data);
        if (result.success) {
            let list = [...this.state.contacts];
            if (!this.state.selectedContact) {
                list.push({ ...result.data, label: result.data.ContactName });
            } else {
                list = list.map(item => {
                    if (item.label === this.state.selectedContact.label) {
                        return { ...result.data, label: result.data.ContactName };
                    }
                    return item;
                });
            }
            this.setState({
                selectedContact: { ...result.data, label: result.data.ContactName },
                openContactDialog: false,
                contacts: list
            });
            this.props.onChange(result.data);
        }
    };

    onContactSelected = (contact: any) => {
        this.setState({ selectedContact: contact });
        this.props.onChange(contact);
    };

    getContacts = async () => {
        let api = new PressRoomApi();
        let result = await api.getContacts();
        if (result.success) {
            let list = result.data.map(item => {
                let newItem = { ...item };
                newItem.label = item.ContactName;
                if (item.Image) {
                    newItem.Image = {
                        Id: item.Image.Id,
                        ImageUrl: item.Image.ImageUrl
                    };
                }
                return newItem;
            });
            this.setState({ contacts: list });
            if (this.props.selectedContactId) {
                let selectedContacts = list.filter(contact => {
                    return contact.Id === this.props.selectedContactId;
                });
                if (selectedContacts.length) {
                    this.setState({ selectedContact: selectedContacts[0] });
                }
            }
        }
    };

    render() {
        console.log("Selected Contact", this.state.selectedContact);
        return (
            <div className={"input-container contact-container" + (this.props.required ? " required" : "")}>
                {this.props.required && <span className="required">*</span>}
                <label>Contact</label>
                {this.state.error && <span className="error-text">{!this.state.selectedContact ? "Required" : "Invalid"}</span>}
                <div className="autocomplete-container">
                    <AutoComplete
                        suggestions={this.state.contacts}
                        onChange={this.onContactSelected}
                        selected={this.state.selectedContact}
                        placeholder="Select a contact"
                        error={this.state.error}
                    />
                    <div
                        onClick={() => {
                            this.setState({ openContactDialog: true });
                        }}
                    >
                        <PRIcon>{this.state.selectedContact ? <PenIcon /> : <PlusIcon />}</PRIcon>
                    </div>
                    <PRDialog
                        open={this.state.openContactDialog}
                        onClose={() => {
                            this.setState({ openContactDialog: false });
                        }}
                    >
                        <div className="download-dialog-header" key="header">
                            <h3>{this.state.selectedContact ? "Edit Contact" : "New Contact"}</h3>
                        </div>
                        <div key="content" className="contact-form">
                            <Form
                                data={{
                                    primaryBtnLabel: this.state.selectedContact ? "Edit Contact" : "Add Contact",
                                    secondaryBtnLabel: "Cancel",
                                    model: this.state.selectedContact
                                }}
                                onCancel={() => {
                                    this.setState({ openContactDialog: false });
                                }}
                                onSubmit={this.onAddContact}
                            >
                                <PRTextField
                                    required
                                    label="Name"
                                    name="ContactName"
                                    value={this.state.selectedContact ? this.state.selectedContact.ContactName : ""}
                                />
                                <FileDrop
                                    name="contactImage"
                                    type="image"
                                    label={
                                        "Contact Image: " + Constants.ImageDimensions.Contact.width + "x" + Constants.ImageDimensions.Contact.height
                                    }
                                    value={
                                        this.state.selectedContact && this.state.selectedContact.Image
                                            ? this.state.selectedContact.Image.ImageUrl
                                            : null
                                    }
                                    canBeCleared
                                ></FileDrop>
                                <PRTextField
                                    required
                                    label="Email"
                                    name="Email"
                                    type="email"
                                    value={this.state.selectedContact ? this.state.selectedContact.Email : ""}
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
                                />
                                <PRTextField
                                    label="Position"
                                    name="ContactDescription"
                                    value={this.state.selectedContact ? this.state.selectedContact.ContactDescription : ""}
                                />
                                <PRTextField
                                    label="Phone number"
                                    name="PhoneNumber"
                                    value={this.state.selectedContact ? this.state.selectedContact.PhoneNumber : ""}
                                />
                            </Form>
                        </div>
                    </PRDialog>
                </div>
            </div>
        );
    }
}
