import React from "react";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import AliceCarousel from "react-alice-carousel";
import "./PRCarousel.scss";
import { PRIcon } from "../../PRIcon/PRIcon";
import PRCarouselItem from "./PRCarouselItem/PRCarouselItem";
import { ArrowIcon } from "../../PRIcon/Icons";

export interface PRCarouselProps {
  width: string;
  data: {
    CategoryLabel?: string;
    Vehicles?: Array<{
      Label: string;
      Image: string;
      Url: string;
    }>;
    Items?: Array<any>;
  };
}

interface PRCarouselState {
  currentIndex: number;
  show: boolean;
}

class PRCarousel extends React.Component<PRCarouselProps, PRCarouselState> {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      show: false
    };
  }

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  responsive = {
    0: { items: 1 },
    600: { items: 2 },
    1024: { items: 3 }
  };

  slideNext = () =>
    this.setState({ currentIndex: this.state.currentIndex + 1 });

  slidePrev = () =>
    this.setState({ currentIndex: this.state.currentIndex - 1 });

  onSlideChanged = e => this.setState({ currentIndex: e.item });

  componentDidMount() {
    setTimeout(() => {
      this.setState({ show: true });
    }, 550);
  }

  getCarouselItems = () => {
    let carouselItems = [];

    this.props.data.Vehicles.map((item, $index) => {
      carouselItems.push(
        <PRCarouselItem
          key={"carousel-item-" + $index}
          data={{ Image: item.Image, Label: item.Label, Url: item.Url }}
        />
      );
    });

    return carouselItems;
  };

  render() {
    const { width } = this.props;
    let classname = "pr-carousel " + width;
    if ((this.state.show)) {
      return (
        <div className={classname}>
          <div
            className="col-sm-1 pr-carousel-control prev"
            onClick={this.slidePrev}
          >
            <PRIcon>
              <ArrowIcon />
            </PRIcon>
          </div>
          <div className="col-sm-10 pr-carousel-container">
            <AliceCarousel
              responsive={this.responsive}
              dotsDisabled={true}
              buttonsDisabled={true}
              slideToIndex={this.state.currentIndex}
              onSlideChanged={this.onSlideChanged}
            >
              {this.props.data.Items
                ? this.props.data.Items
                : this.getCarouselItems()}
            </AliceCarousel>
          </div>
          <div
            className="col-sm-1 pr-carousel-control next"
            onClick={this.slideNext}
          >
            <PRIcon>
              <ArrowIcon />
            </PRIcon>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withWidth()(PRCarousel);
