import * as React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import "./PRAddToCart.scss";
import { PlusIcon } from "../../PRIcon/Icons";
import { Notification } from "../../Notification/Notification";
import { CookieHelper } from "../../../services/cookieHelper";

interface IPRAddToCartProps {
  cartItem?: any;
  className?: string;
}

interface IPRAddToCartState {
  showNotification: boolean;
}

class PRAddToCart extends React.Component<IPRAddToCartProps, IPRAddToCartState> {

  constructor(props) {
    super(props);

    this.state = { showNotification: false };
  }

  addToCart = (e) => {
    e.stopPropagation();
    let cookieHelper = new CookieHelper();
    let cookie = cookieHelper.getCookie("prcart");
    let items: Array<any> = cookie ? JSON.parse(cookie) : [];
    if (items.filter(item => item.id === this.props.cartItem.id).length === 0) {
      items.push(this.props.cartItem);
    }
    cookieHelper.setCookie("prcart", items);

    this.setState({ showNotification: true });
  };

  getButton = () => {
    return (
      <div className={"PR-add-to-cart-icon " + this.props.className} onClick={this.addToCart} key="add-to-cart-icon">
        <PRIcon className="plus">
          <PlusIcon />
        </PRIcon>
      </div>
    );
  }

  render() {
    return (
      [this.getButton(),<Notification key="add-to-cart-notification"
        message="You added this item to your basket"
        open={this.state.showNotification}
        onClose={this.handleNotificationClose} />
      ]
    );
  }

  handleNotificationClose = () => {
    this.setState({ showNotification: false });
  };

}

export default PRAddToCart;
