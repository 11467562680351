import React from 'react';

export default class PRDivider extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let style = {padding: '30px 0'};
        return (
            <div className="pr-divider" style={style}></div>
        );
    }
}