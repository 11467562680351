import React from "react";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import { DatePicker } from "material-ui-pickers";
import "./Date.scss";
import { CalendarIcon } from "../../PRIcon/Icons"
import { PRIcon } from "../../PRIcon/PRIcon"

interface IDateProps {
  label?: string;
  onChange?: Function;
  selectedDate?: string;
}

interface IDateState {
  selectedDate?: any;
}

export class Date extends React.Component<IDateProps, IDateState> {
  private calendarRef: any = null;

  constructor(props: IDateProps) {
    super(props);

    this.state = {
      selectedDate: this.convertNullValuesToNull(this.props.selectedDate)
    };

    this.calendarRef = React.createRef();
  }

  componentDidUpdate(prevProps: IDateProps) {
    // If the selectedDate has been changed by the parent, update the state.
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.setState({ selectedDate: this.convertNullValuesToNull(this.props.selectedDate) });
    }
  }

  handleDateChange = date => {
    this.props.onChange(date.format());
    this.setState({ selectedDate: date });
  };

  convertNullValuesToNull = (value) => {
    return (!value || value === "null") ? null : value;
  }

  render() {
    const { selectedDate } = this.state;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="pr-date">
          <DatePicker
            keyboard
            label={this.props.label}
            format="DD/MM/YYYY"
            // handle clearing outside => pass plain array if you are not controlling value outside
            mask={value =>
              value
                ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                : []
            }
            value={selectedDate}
            onChange={this.handleDateChange}
            disableOpenOnEnter
            animateYearScrolling={false}
            className="datepicker-root"
            InputLabelProps={{ classes: { root: "input-label-root" } }}
            InputProps={{ classes: { root: "input-root", input: "input-input" } }}
            keyboardIcon={<PRIcon className="calendar-icon"><CalendarIcon /></PRIcon>}
            InputAdornmentProps={{ classes: { root: "input-adornment-root" } }}
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}
