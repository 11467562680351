import React from "react";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import PRButton from "../PRButton/PRButton";
import { PRIcon } from "../../PRIcon/PRIcon";
import "./PRImageSlider.scss";
import { ArrowIcon } from "../../PRIcon/Icons";

export interface PRImageSliderProps {
  data: {
    Items?: Array<{
      Src: string;
      AltText: string;
      Date: string;
      Copy: string;
      CtaLabel: string;
      Url: string;
      key?: string;
    }>;
  };
  width: string;
}

export interface PRImageSliderState {
  activeIndex: number;
}

class PRImageSlider extends React.Component<
  PRImageSliderProps,
  PRImageSliderState
  > {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  animating = false;

  myDiv = null;

  node = null;

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.data.Items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.data.Items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = newIndex => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  carouselNextButton = () => {
    if (this.props.width === "xs") {
      return "";
    } else {
      return (
        <div
          className="col-sm-1 col-lg-2 carousel-control-next"
          onClick={this.next}
        >
          <PRIcon style={{ width: "105px", height: "105px" }} color="white">
            <ArrowIcon />
          </PRIcon>
        </div>
      );
    }
  };

  carouselPrevButton = () => {
    if (this.props.width === "xs") {
      return "";
    } else {
      return (
        <div
          className="col-sm-1 col-lg-2 carousel-control-prev"
          onClick={this.previous}
        >
          <PRIcon style={{ width: "105px", height: "105px" }} color="white">
            <ArrowIcon />
          </PRIcon>
        </div>
      );
    }
  };

  getCTA = (label, link) => {
    if (this.props.width === "xs" || !link) {
      return "";
    } else {
      return (
        <div className="cta-container">
          <PRButton data={{ type: "tertiary", link: link }}>{label || "Read More"}</PRButton>
        </div>
      );
    }
  };

  updateCarouselIndicator() {
    setTimeout(() => {
      if (this.props.width === "xs") {
        let element = this.node as HTMLElement;
        let height = (element.querySelectorAll(".carousel-item-image")[
          this.state.activeIndex
        ] as HTMLElement).clientHeight;
        let indicators = element.querySelector(
          ".carousel-indicators"
        ) as HTMLElement;

        if (height > 0) {
          indicators.style.top = height - 20 + "px";
        }
      } else {
        let element = this.node as HTMLElement;
        let indicators = element.querySelector(
          ".carousel-indicators"
        ) as HTMLElement;

        indicators.style.top = "unset";
      }
    }, 200);
  }

  componentDidMount() {
    this.updateCarouselIndicator();
    window.addEventListener("resize", this.updateCarouselIndicator.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.updateCarouselIndicator.bind(this)
    );
  }

  render() {
    const { activeIndex } = this.state;
    const { width } = this.props;

    const slides = this.props.data.Items.map((item, $index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={"carousel-item-image-" + $index}
        >
          <div
            className="carousel-item-image"
            style={{ backgroundImage: "url(" + item.Src + ")" }}
          />
          <div className="carousel-text-container justify-content-center">
            <div className="carousel-text justify-content-start">
              <div className="carousel-text-inner col-md-5 col-sm-6 offset-md-1 offset-sm-2">
                <div>
                  <div className="carousel-text-date" dangerouslySetInnerHTML={{ __html: item.Date }} />
                </div>
                <div className="carousel-text-copy">
                  {item.Url ?
                    <a className="carousel-text-link" href={item.Url}><div dangerouslySetInnerHTML={{ __html: item.Copy }} /></a> :
                    <div dangerouslySetInnerHTML={{ __html: item.Copy }} />}
                  {this.getCTA(item.CtaLabel, item.Url)}
                </div>
              </div>
            </div>
          </div>
        </CarouselItem>
      );
    });

    let carouselClass = "pr-image-slider " + width;
    this.props.data.Items.map(item => {
      let itemKey: string = "";
      itemKey += Object.keys(item).map((key: string) => {
        if (item[key]) {
          return item[key].split("").reduce(function (a, b) {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
          }, 0);
        }
      });
      item.key = itemKey;
    });
    return (
      <div ref={node => (this.node = node)}>
        <Carousel
          className={carouselClass}
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators
            items={this.props.data.Items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          {this.carouselPrevButton()}
          {this.carouselNextButton()}
        </Carousel>
      </div>
    );
  }
}

export default withWidth()(PRImageSlider);
