import React from "react";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import "./Autocomplete.scss";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef();
        },
        classes: classes
      }}
      {...other}
    />
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div" classes={{root: "menu-item-root"}}>
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: "bold" }}>
              {part.text}
            </span>
          ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            );
        })}
      </div>
    </MenuItem>
  );
}

interface IAutoCompleteProps {
  suggestions?: Array<any>;
  selected?: any;
  onChange?: Function;
  placeholder?: string;
  error?: boolean;
}

interface IAutoCompleteState {
  single: string;
  popper: string;
  suggestions: Array<any>;
  selected: any;
  error: boolean;
}

export class AutoComplete extends React.Component<IAutoCompleteProps, IAutoCompleteState> {
  constructor(props: IAutoCompleteProps) {
    super(props);

    this.state = {
      single: this.props.selected ? this.props.selected.label : "",
      popper: "",
      suggestions: [],
      selected: this.props.selected || { label: "" },
      error: this.props.error
    };
  }

  popperNode = null;

  componentDidUpdate(prevProps) {
    // If the selected value has been changed by the parent, update the state.
    if (prevProps.selected !== this.props.selected) {
      this.setState({
        selected: this.props.selected || { label: "" },
        single: this.props.selected ? this.props.selected.label : ""
      });
    }
    // If the error value has been changed by the parent, update the state.
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : this.props.suggestions.filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = () => (event, { newValue }) => {
    let filtered = this.state.suggestions.filter(suggestion => {
      return suggestion.label === newValue;
    });

    if (filtered.length > 0) {
      this.props.onChange(filtered[0]);
    } else {
      if (this.state.selected.label !== "") {
        this.props.onChange(null);
      }
    }

    this.setState({
      single: filtered.length > 0 ? filtered[0].label : newValue,
      selected: filtered.length > 0 ? filtered[0] : { label: "" }
    });
  };

  render() {
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion
    };

    return (
      <div className="autocomplete">
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            placeholder: this.props.placeholder,
            value: this.state.single,
            onChange: this.handleChange(),
            classes: { underline: "input-underline", error: "input-error" },
            error: this.state.error
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square classes={{root: "paper-root"}}>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}
