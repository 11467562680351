import React from "react";
import withWidth from "@material-ui/core/withWidth";
import PRBanner from "../PRBanner/PRBanner";
import PRSelectSlider from "../../PRSelectSlider/PRSelectSlider";
import PRDropdown from "../Form/Fields/PRDropdown/PRDropdown";
import "./PRComplexTab.scss";

export interface PRComplexTabsProps {
  data: {
    Banner?: {
      BannerImage?: string;
      HeaderText: string;
      SubHeader?: string;
    };
    Header: string;
    FlagLabel?: string;
    Filters?: Array<{
      Key: string;
      Values?: Array<{
        Value: any;
        Name: string;
        Selected: boolean;
      }>;
    }>;
    TabItems: Array<{
      Label: string;
    }>;
    isSitecore?: boolean;
    Children?: Array<string>;
  };
  options: {
    noTabs: boolean;
  };
  width: string;
  onFilterChanges?: Function;
}

class PRComplexTabs extends React.Component<PRComplexTabsProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    currentTab: 0
  };
  node = null;

  handleChange = value => {
    this.setState({ currentTab: value });
  };

  handleFilterChange = (id, name, value) => {
    this.insertParam(id, value);
  };

  componentDidMount() {
    if (this.node) {
      var arr = this.node.getElementsByTagName("script");
      for (var n = 0; n < arr.length; n++) eval(arr[n].innerHTML);
    }
  }

  insertParam = (key, value) => {
    key = encodeURI(key);
    value = encodeURI(value);

    var kvp = document.location.search.substr(1).split("&");

    var i = kvp.length;
    var x;
    while (i--) {
      x = kvp[i].split("=");

      if (x[0] == key) {
        x[1] = value;
        kvp[i] = x.join("=");
        break;
      }
    }

    if (i < 0) {
      kvp[kvp.length] = [key, value].join("=");
    }

    if (this.props.onFilterChanges) {
      window.history.pushState({}, "new filter", "?" + kvp.join("&"));
      this.props.onFilterChanges(kvp);
    } else {
      //this will reload the page, it's likely better to store this until finished
      document.location.search = kvp.join("&");
    }
  };

  render() {
    let self = this;
    const { currentTab } = this.state;

    let tabList = [];

    let tabs = [];

    if (!this.props.options || !this.props.options.noTabs) {
      this.props.data.TabItems.map((child, $index) => {
        let className =
          "complex-tab" + (this.state.currentTab === $index ? " active" : "");
        tabList.push(
          <div
            key={$index}
            onClick={() => {
              this.handleChange($index);
            }}
            className={className}
          >
            {child.Label ? child.Label : $index + 1}
          </div>
        );
        let tabsClass = this.state.currentTab === $index ? "active" : "";
        tabs.push(
          <div
            key={$index}
            className={tabsClass}
            dangerouslySetInnerHTML={{
              __html: this.props.data.Children[$index]
            }}
          />
        );
      });
    }

    let className =
      "complex-tabs" + (this.props.data.FlagLabel ? " includes-flag" : "");

    return (
      <div>
        {this.props.data.Banner && <PRBanner data={this.props.data.Banner} />}

        <div className={className}>
          {this.props.data.FlagLabel && (
            <div className="press-kit-flag-container">
              <div className="press-kit-flag">
                <span>{this.props.data.FlagLabel}</span>
              </div>
            </div>
          )}
          <div className="header-container">
            <div className="header-inner-container">
              <div className="title-filter">
                <h1 className="light">{this.props.data.Header}</h1>
                {this.props.width !== "xs" &&
                  this.props.width !== "sm" &&
                  this.props.data.Filters && (
                    <div className="filters">
                      {this.props.data.Filters.map((filter, $index) => {
                        return (
                          <PRDropdown
                            key={$index}
                            id={filter.Key}
                            onChange={this.handleFilterChange}
                            filter={{ Values: filter.Values }}
                          />
                        );
                      })}
                    </div>
                  )}
                {(this.props.width === "xs" || this.props.width === "sm") &&
                  this.props.data.Filters && (
                    <div className="filters">
                      {this.props.data.Filters.map((filter, $index) => {
                        return (
                          <div key={$index} className="filter">
                            <PRSelectSlider
                              data={{ Values: filter.Values }}
                              onChange={(name, value) => {
                                this.handleFilterChange(
                                  filter.Key,
                                  name,
                                  value
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
              {(!this.props.options || !this.props.options.noTabs) && (
                <div className="complex-tab-list">{tabList}</div>
              )}
            </div>
          </div>
          <div className="pr-tabs-content" ref={node => (this.node = node)}>
            {(!this.props.options || !this.props.options.noTabs) && tabs}
            {this.props.options &&
              this.props.options.noTabs &&
              this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default withWidth()(PRComplexTabs);
