import * as React from 'react';
import './PRBodyCopy.scss';

export interface BodyCopyProps {
    data: {
        BodyContent?: string;
    };
}

export default class PRBodyCopy extends React.Component<BodyCopyProps, {}> {

    render() {
        return (
            <div className="pr-body-copy" dangerouslySetInnerHTML={{ __html: this.props.data.BodyContent }}></div>
        );
    }
}
