import { RequestHandler } from "./requestHandler";
import Constants from "../components/constants";
import moment from 'moment';

export class PressRoomApi {
    requestHandler: RequestHandler;

    constructor() {
        let url = "";
        if (window.location.host.indexOf("localhost") === -1) {
            url =
                window.location.protocol +
                "//" +
                window.location.host +
                "/pressroom/toyota/api/";
        } else {
            url = "http://toyota.local/pressroom/toyota/api/";
        }
        this.requestHandler = new RequestHandler(url);
    }

    private transformMediaData = (items) => {
        return items.map((item) => {
            return {
                Id: item.id,
                Title: item.title,
                Copy: item.description,
                Image: item.thumbnailurl,
                Date: moment(item.date).format(Constants.DateFormat),
                Type: item.type,
                LinkUrl: item.linkurl,
                hrAssert: item.hrAssert,
                lrAssert: item.lrAssert,
                Locationlatitude: item.locationlatitude,
                Locationlongitude: item.locationlongitude,
                Published: item.Published
            }
        })
    };

    private getBase64Data = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve, reject) => {
            reader.onload = () => {
                resolve(reader.result);
            }
        });
    };

    private async initializeMediaUpload(data: any): Promise<any> {
        return await this.requestHandler.post("Aprimo/InitializeUpload", JSON.stringify(data));
    }

    private async uploadMediaSegment(id: string, data: any): Promise<any> {
        return await this.requestHandler.post("Aprimo/UploadSegment?metaDataId=" + id, JSON.stringify(data));
    }

    private async commitMediaUpload(id: string, data: any): Promise<any> {
        return await this.requestHandler.post("Aprimo/CommitUpload?metaDataId=" + id, JSON.stringify(data));
    }

    private async createOrUpdateMedia(data: any) {
        return await this.requestHandler.post("MediaMetaData/CreateOrUpdateMedia", JSON.stringify(data));
    }

    private async uploadFileInChunks(file, storage) {
        let chunks = await this.getFileChunks(file);
        let chunkNum = chunks.length;
        let fileType=  file.type.split('/')[0];
        let filename= `${Date.now()}-${file.name}`;

        let initialize = await this.initializeMediaUpload({
            fileType: fileType,
            extension: file.type.split('/')[1],
            nochunks: chunkNum,
            filename: filename,
            size: file.size,
            Storage: storage
        });

        if (!initialize.success || !initialize.data.Success) {
            return { success: false };
        }

        let data = initialize.data;
        let upload = await this.sendFileChunks(data.Id, chunks , storage, fileType,filename);

        if (!upload.success) {
            return { success: false };
        }

        return await this.commitMediaUpload(data.Id,{ filename:filename, segmentcount: chunkNum ,storage :storage});
    }

    private getFileChunks = async (file) => {
        let bufferChunkSize = (1020 * 1020);
        let startPosition = 0;
        let endPosition = bufferChunkSize;
        let prefix = 'data:' + file.type + ';base64';

        let chunks = [];
        while (startPosition < file.size) {
            let blob = file.slice(startPosition, endPosition);
            let reader = new FileReader();
            let promise = new Promise((resolve, reject) => {
                reader.onload = () => {
                    let chunk = prefix + ',' + this.arrayBufferTobase64(reader.result);
                    resolve(chunk);
                };
            });
            reader.readAsArrayBuffer(blob);
            chunks.push(await promise);
            startPosition = endPosition;
            endPosition = startPosition + bufferChunkSize;
        }
        return chunks;
    };

    private arrayBufferTobase64 = (buffer) => {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    private sendFileChunks = async (id, chunks , storage , fileType , filename) => {
        let partCount = 0;
        let totalChunk = chunks.length;
        let error = false;
        while (chunks.length > 0 && !error) {
            var chunk = chunks.shift();
            let api = new PressRoomApi();
            let result = await api.uploadMediaSegment(id,  { 
                nochunks: totalChunk, 
                partnumber: partCount, 
                storage : storage , 
                fileType: fileType,
                filename:filename, 
                chunk: chunk 
            });
            if (!result.success) {
                error = true;
            }

            partCount++;
            
        }
        return { success: !error };
    }

    async getPressKitsForPressRelease(id: string): Promise<any> {
        return await this.requestHandler.get("pressrelease/GetPressReleasekits?id=" + id);
    }

    async deletePressRelease(id: string): Promise<any> {
        return await this.requestHandler.delete("pressrelease/DeletePressRelease?id=" + id);
    }

    async deletePressKit(id: string): Promise<any> {
        return await this.requestHandler.delete("pressrelease/DeletePressKit?id=" + id);
    }

    async deleteMedia(id: string): Promise<any> {
        return await this.requestHandler.delete("MediaMetaData/DeleteMedia?metaDataId=" + id);
    }

    async publishPressRelease(id: string): Promise<any> {
        return await this.requestHandler.get("pressrelease/PublishPressRelease?id=" + id);
    }

    async publishPressKit(id: string): Promise<any> {
        return await this.requestHandler.get("pressrelease/PublishPressKit?id=" + id);
    }

    async savePressRelease(data: any): Promise<any> {
        // upload the banner
        if (data.Banner && !data.Banner.Id) {
            let result = await this.uploadMedia(data.Banner, Constants.MediaFolders.Banners);
            if (result.success) {
                data.Banner = result.data;
            }
        }

        // upload the thumbnail
        if (data.ThumbnailImage && !data.ThumbnailImage.Id) {
            let result = await this.uploadMedia(data.ThumbnailImage, Constants.MediaFolders.Thumbnails);
            if (result.success) {
                data.ThumbnailImage = result.data;
            }
        }

        return await this.requestHandler.post("pressrelease/CreateOrUpdatePressRelease", JSON.stringify(data));
    }

    async savePressKit(data: any): Promise<any> {
        // upload the banner
        if (data.Banner && !data.Banner.Id) {
            let result = await this.uploadMedia(data.Banner, Constants.MediaFolders.Banners);
            if (result.success) {
                data.Banner = result.data;
            }
        }

        // upload the thumbnail
        if (data.ThumbnailImage && !data.ThumbnailImage.Id) {
            let result = await this.uploadMedia(data.ThumbnailImage, Constants.MediaFolders.Thumbnails);
            if (result.success) {
                data.ThumbnailImage = result.data;
            }
        }

        return await this.requestHandler.post("pressrelease/CreateOrUpdatePressKit", JSON.stringify(data));
    }

    async saveContact(data: any): Promise<any> {
        // upload the image
        if (data.contactImage) {
            let result = await this.uploadMedia(data.contactImage, Constants.MediaFolders.Contacts);
            if (result.success) {
                data.Image = result.data;
            }
        }

        return await this.requestHandler.post("pressrelease/CreateOrUpdateContact", JSON.stringify(data));
    }

    async getPressRelease(id: string): Promise<any> {
        return await this.requestHandler.get("pressrelease/GetPressRelease?id=" + id);
    }

    async getPressKit(id: string): Promise<any> {
        return await this.requestHandler.get("pressrelease/GetPressKit?id=" + id);
    }

    async getMedia(id: string): Promise<any> {
        return await this.requestHandler.get("MediaMetaData/GetMedia?Id=" + id);
    }

    async getContacts(): Promise<any> {
        return await this.requestHandler.get("pressrelease/getcontacts");
    }

    async getCategories(root?: string): Promise<any> {
        return await this.requestHandler.get("pressrelease/GetCategories" + (root ? "?root=" + root : ""));
    }

    async getCategoriesForSelected(categoryId: string): Promise<any> {
        return await this.requestHandler.get("pressrelease/GetCategoriesBySelectedItem?selected=" + categoryId);
    }

    async searchAdminMedia(filters: any): Promise<any> {
        let response = await this.requestHandler.post("search/GetAdminMedia", JSON.stringify(filters));
        if (response.data && response.data.Results) {
            let results = this.transformMediaData(response.data.Results);
            response.data.Results = results;
        }
        return response;
    }

    async searchMedia(filters: any): Promise<any> {
        let response = await this.requestHandler.post("search/GetMedia", JSON.stringify(filters));
        if (response.data && response.data.Results) {
            let results = this.transformMediaData(response.data.Results);
            response.data.Results = results;
        }
        return response;
    }

    async uploadMedia(image: any, folder: string): Promise<any> {
        let data = new FormData();
        data.append("folder", folder);
        data.append("image", image);
        return await this.requestHandler.postAsForm("pressrelease/UploadMedia", data);
    }

    async createMedia(data: any, type: string, storage : string): Promise<any> {
        let requestData: any = {
            FileType: type,
            Title: data.title,
            Keywords: data.keywords,
            ShortDescription: data.description,
            CategoryId: data.category,
            PublishAt: data.publishAt
        };

        // upload high res
        let highResUpload = await this.uploadFileInChunks(data.highResFile , storage);
        if (!highResUpload.success || !highResUpload.data.Success) {
            return { success: false };
        }
        requestData.HighResolutionAssert = highResUpload.data.Id;

        // upload low res, or thumbnail if low res not set
        let lowResUpload = await this.uploadFileInChunks(data.lowResImage || data.thumbnail, Constants.StoragTypes.Sitecore);
        if (!lowResUpload.success || !lowResUpload.data.Success) {
            return { success: false };
        }
        requestData.LowResolutionAssert = lowResUpload.data.Id;

        let thumbnailData = await this.getBase64Data(data.thumbnail);
        requestData.Thumbnail = { ImageName: data.thumbnail.name, ImageFolder: Constants.MediaFolders.Thumbnails, ImageFile: thumbnailData };

        // create media
        return await this.createOrUpdateMedia(requestData);
    }

    async updateMedia(newData: any, oldData: any, storage : string): Promise<any> {
        // update the properties that have changed
        let requestData = { ...oldData };
        requestData.Title = newData.title || requestData.Title;
        requestData.Keywords =  newData.keywords === undefined ?  requestData.Keywords : newData.keywords ;
        requestData.ShortDescription =  newData.description === undefined ?  requestData.ShortDescription : newData.description ;
        requestData.CategoryId =  newData.category || requestData.CategoryId;
        requestData.PublishAt = newData.publishAt === undefined ? requestData.PublishAt : newData.publishAt; // This one can be cleared

        // upload high res if changed
        if (newData.highResFile) {
            let highResUpload = await this.uploadFileInChunks(newData.highResFile, storage);
            if (!highResUpload.success || !highResUpload.data.Success) {
                return { success: false };
            }
            requestData.HighResolutionAssert = highResUpload.data.Id;
        }

        // upload low res if changed and exists
        if (newData.lowResImage) {
            let lowResUpload = await this.uploadFileInChunks(newData.lowResImage, Constants.StoragTypes.Sitecore);
            if (!lowResUpload.success || !lowResUpload.data.Success) {
                return { success: false };
            }
            requestData.LowResolutionAssert = lowResUpload.data.Id;
        }

        // update thumbnail if changed
        if (newData.thumbnail) {
            let thumbnailData = await this.getBase64Data(newData.thumbnail);
            requestData.Thumbnail = { ImageName: newData.thumbnail.name, ImageFolder: Constants.MediaFolders.Thumbnails, ImageFile: thumbnailData };
        }

        // update media
        return await this.createOrUpdateMedia(requestData);
    }

    downloadFiles(ids: Array<string>) {
        window.location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            "/pressroom/toyota/api/pressrelease/DownloadFiles?" +
            ids.map(id => "ids=" + id).join("&");
    }
}