import React from "react";
import Placeholder from "../../../js/extensions/Placeholder";
import "./scaffolding.scss";
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';

export interface PRSectionProps {
    data: {
        BackgroundColored?: boolean;
        SectionHeader?: string;
        IsShownInMobile?: boolean;
    }
    placeholder?: boolean;
    width: string;
    isEditing?: boolean;

}

class PRSection extends React.Component<PRSectionProps, {}> {

    static propTypes = {
        width: PropTypes.string.isRequired
    };

    render() {
        let colored = this.props.data.BackgroundColored ? ' colored' : ''
        let className = "PR-section" + colored;

        let header = null;
        if (this.props.data.SectionHeader) {
            header = <div className="section-header" dangerouslySetInnerHTML={{ __html: this.props.data.SectionHeader }} ></div>
        }

        if (this.props.isEditing || (!this.props.isEditing)) {
            return (
                <div className={className}>
                    <div className="PR-section-container">
                        {header}
                        <Placeholder placeholderKey={'tp-section'} isDynamic={true} placeholder={this.props.placeholder}>
                            {this.props.children}
                        </Placeholder>
                    </div>
                </div>
            );
        }

        return (<div></div>);
    }
}

export default withWidth()(PRSection);