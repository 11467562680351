export class CookieHelper {
    getCookie = (name: string) => {
        name = name + "=";
        let result: string;
        let cookies = document.cookie.split(";");
        cookies.forEach(cookie => {
            cookie = cookie.trim();
            if (cookie.indexOf(name) === 0) {
                result = this.restoreHtml(cookie.substring(name.length, cookie.indexOf("expires=")));
            }
        });
        return result;
    };

    setCookie = (name: string, value: any) => {
        let date = new Date();
        document.cookie =
            name + "=" +
            this.cleanHtml(JSON.stringify(value || "")) +
            "expires=" +
            date.setTime(date.getTime() + 60 * 60 * 1000) +
            "; path=/";
    };
      restoreHtml = (value) => {
        let restoreHtml: string="";  
        if (!(typeof value === 'string' && value.trim().length === 0)) {        
        {
          restoreHtml= value.replace("^sup^", "<sup>").replace("^/sup^", "</sup>");  
        }
        return  restoreHtml;  
      }};
     
      cleanHtml  = (value) => {        
        let cleanHtml: string="";  
        if (!(typeof value === 'string' && value.trim().length === 0)) {   
        {
          cleanHtml= value.replace(/<sup>/gi, "^sup^").replace("</sup>", "^/sup^");  
        }
        return  cleanHtml;   
      }};
}
