import React from "react";
import PRDialog from "../../PageContent/PRDialog/PRDialog";
import PRFilter from "../../PageContent/PRFilter/PRFilter";
import PRButton from "../../PageContent/PRButton/PRButton";
import "./FileSelection.scss";
import SearchResults from "../../SearchResults/SearchResults";
import PRPagination from "../../PageContent/PRPagination/PRPagination";
import { PressRoomApi } from "../../../services/pressRoomApi";

interface ISelectedItem {
  Id: string;
  Url?: string;
  Title?: string;
  ShortDescription?: string;
}

interface IFileSelectionProps {
  onChange?: Function;
  fileType?: string;
  label: string;
  selectedItems?: Array<ISelectedItem>;
  multipleSelection?: boolean;
  restrictedToId?: string;
}

interface IFileSelctionState {
  openUploadDialog: boolean;
  selected: number;
  selectedItems: Array<ISelectedItem>;
  items: Array<any>;
  totalItems: number;
  filters: any;
}

export class FileSelection extends React.Component<IFileSelectionProps, IFileSelctionState> {
  constructor(props: IFileSelectionProps) {
    super(props);

    this.state = {
      openUploadDialog: false,
      selected: -1,
      selectedItems: this.props.selectedItems,
      items: [],
      totalItems: 0,
      filters: {
        Query: "",
        SortType: "Newest",
        DateFrom: "2010-01-01",
        DateTo: "2025-09-25",
        Page: 1,
        PerPage: 10,
        Type: this.props.fileType,
        IncludeRestrictedToId: this.props.restrictedToId
      }
    };
  }

  componentDidMount() {
    this.getItems(this.state.filters);
  }

  getItems = async (filters) => {
    let api = new PressRoomApi();
    let response = await api.searchAdminMedia({ ...filters, Type: [filters.Type] });
    if (response.success) {
      this.setState({ items: response.data.Results, totalItems: response.data.TotalCount });
    }
  }

  onCardSelect = (selected: boolean, selectedItem: ISelectedItem) => {
    if (this.props.multipleSelection) {
      let selectedItems = [...this.state.selectedItems];
      if (selected) {
        selectedItems.push(selectedItem);
        this.setState({ selectedItems: selectedItems });
      } else {
        selectedItems = selectedItems.filter(item => {
          if (item.Id !== selectedItem.Id) {
            return item;
          }
        });
        this.setState({ selectedItems: selectedItems });
      }
    } else {
      this.setState({ selectedItems: [selectedItem] });
    }
  };

  submit = () => {
    this.props.onChange(this.state.selectedItems);
    this.closeDialog();
  };

  closeDialog = () => {
    this.setState({ openUploadDialog: false });
  };

  onFilterChange = (newFilters) => {
    this.setState({ filters: newFilters });
    this.getItems(newFilters);
  };

  onPageChange = (currentPage) => {
    let newFilters = { ...this.state.filters };
    newFilters.Page = currentPage;
    this.setState({ filters: newFilters });
    this.getItems(newFilters);
  };

  getUploadDialog = () => {
    return (
      <PRDialog
        maxWidth="md"
        className="file-selection-dialog"
        open={this.state.openUploadDialog}
        onClose={() => {
          this.setState({ openUploadDialog: false });
        }}
      >
        <div className="download-dialog-header" key="header">
          <h3>Select {this.props.label.toLowerCase()}s</h3>
        </div>
        <div key="content" className="file-select-dialog-content">
          <PRFilter
            mediaType="image"
            label="Admin Dashboard"
            filters={this.state.filters}
            onFilterChange={this.onFilterChange}
          />
          <SearchResults
            items={this.state.items}
            selectedItems={this.state.selectedItems}
            onCardSelect={this.onCardSelect}
            actions={{ select: true }}
          />
          <PRPagination
            onPageChange={this.onPageChange}
            data={{ current: this.state.filters.Page, totalItems: this.state.totalItems, itemsPerPage: this.state.filters.PerPage }}>
          </PRPagination>


          <div className="button-container">
            <PRButton
              data={{ type: "primary" }}
              className="submit-btn"
              onClick={this.submit}
            >
              Done
            </PRButton>
            <PRButton data={{ type: "secondary" }} onClick={this.closeDialog}>
              Cancel
            </PRButton>
          </div>
        </div>
      </PRDialog>
    );
  };

  onOpenDialog = () => {
    this.setState({
      openUploadDialog: true,
      selected: -1,
      selectedItems: this.props.selectedItems
    });
  };

  render() {
    return (
      <div className="file-upload-container">
        <div className="dropzone-container" onClick={this.onOpenDialog}>
          {this.props.children}
        </div>

        {this.state.openUploadDialog && this.getUploadDialog()}
      </div>
    );
  }
}
