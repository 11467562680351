import React from "react";
import "./NewItem.scss";
import PRButton from "../../PageContent/PRButton/PRButton";
import { MediaForm } from "../MediaForm/MediaForm";
import Constants from "../../constants";

interface INewItemProps {
  type: string;
  onItemCreated: Function;
}

export class NewItem extends React.Component<INewItemProps> {

  handleSave = () => {
    this.props.onItemCreated();
  }

  render() {
    return (
      <div className="new-item-button">
        {(this.props.type === "Image" || this.props.type === "Video") &&
          <MediaForm type={this.props.type} onSave={this.handleSave}>
            <PRButton data={{ type: 'primary' }}>Add new</PRButton>
          </MediaForm>
        }
        {this.props.type === "PressRelease" &&
          <PRButton data={{ type: 'primary', link: Constants.Links.PressReleaseForm }}>Add new</PRButton>
        }
        {this.props.type === "PressKit" &&
          <PRButton data={{ type: 'primary', link: Constants.Links.PressKitForm }}>Add new</PRButton>
        }
      </div>
    );
  }
}
