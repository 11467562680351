import React from "react";
import PRButton from "../PRButton/PRButton";
import "./Form.scss";

export interface FormProps {
  data?: {
    primaryBtnLabel: string;
    secondaryBtnLabel: string;
    model?: any;
  };
  onCancel: Function;
  onSubmit: Function;
}

interface IFormState {
  formIsValid: boolean;
  data: any;
}

export default class Form extends React.Component<FormProps, IFormState> {
  constructor(props) {
    super(props);

    this.state = {
      formIsValid: true,
      data: { ...this.props.data.model }
    };
  }

  formEl = null;

  componentDidUpdate(prevProps) {
    if (this.props.data.model && this.props.data.model !== prevProps.data.model) {
      this.setState({ data: { ...this.props.data.model } });
    }
  }

  validate = () => {
    if (this.formEl.checkValidity()) {
      this.props.onSubmit(this.state.data);
    }
    this.setState({ formIsValid: this.formEl.checkValidity() });
  };

  handleChange = (value, name) => {
    let newData = { ...this.state.data };
    newData[name] = value;
    this.setState({ data: newData });
  };

  getFormElements = () => {

    if (this.formEl) {
      let elements = [];
      React.Children.toArray(this.props.children).filter(child => !!child).map((child, $index) => {
        let props = { ...(child as any).props };
        props.onChange = this.handleChange;
        if (!this.state.formIsValid && props.name) {
          let element = this.formEl.elements[props.name];
          if (element) {
            let invalid = !element.validity.valid;
            props.error = invalid;
          }
        }
        props.key = $index;
        elements.push(React.cloneElement(child as any, props));
      });

      return elements;
    }
  };

  render() {
    return (
      <div className="form-container">
        <form ref={form => (this.formEl = form)} noValidate className="form">
          {this.getFormElements()}
        </form>
        <div className="action-container">
          <PRButton
            data={{ type: "primary" }}
            className="submit-btn"
            onClick={this.validate}
          >
            {this.props.data.primaryBtnLabel}
          </PRButton>
          <PRButton data={{ type: "secondary" }} onClick={this.props.onCancel}>
            {this.props.data.secondaryBtnLabel}
          </PRButton>
        </div>
      </div>
    );
  }
}
