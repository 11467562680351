import React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import Dialog from "@material-ui/core/Dialog";
import "./PRVideoCard.scss";
import { PlayIcon } from "../../PRIcon/Icons";

export interface PRVideoCardProps {
  data: {
    ImageUrl: string;
    VideoUrl: string;
    Title?: string;
    Description?: string;
  };
}

export default class PRVideoCard extends React.Component<PRVideoCardProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {
    openVideoDialog: false,
    height: "800px"
  };

  handleVideoClick = () => {
    this.updateDimensions();
    this.setState({ openVideoDialog: true });
  };

  handleVideoClose = () => {
    this.setState({ openVideoDialog: false });
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <div className="pr-video-card">
        <div className="pr-video-card-container">
          <div className="pr-image-container">
            <div
              className="pr-image-container"
              dangerouslySetInnerHTML={{ __html: this.props.data.ImageUrl }}
            />
            <div className="card-video-icon" onClick={this.handleVideoClick}>
              <PRIcon>
                <PlayIcon />
              </PRIcon>
            </div>
          </div>
          {(this.props.data.Title || this.props.data.Description) && (
            <div className="pr-content-container">
              <div
                className="title"
                dangerouslySetInnerHTML={{ __html: this.props.data.Title }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.Description
                }}
              />
            </div>
          )}
        </div>
        {
          <Dialog
            fullScreen
            open={this.state.openVideoDialog}
            onClose={this.handleVideoClose}
          >
            <div className="video-popup">
              <div className="close-btn" onClick={this.handleVideoClose}>
                X
              </div>
              <div className="video-container">
                <video controls autoPlay style={{ height: this.state.height }}>
                  <source src="{this.props.data.VideoUrl}" type="video/mp4" />
                </video>
              </div>
            </div>
          </Dialog>
        }
      </div>
    );
  }
}
