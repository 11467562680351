import React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import "./PRContactCard.scss";
import { NewsletterIcon, PhoneIcon } from "../../PRIcon/Icons";
import objectFitImages from 'object-fit-images';

export interface PRContactCardProps {
  data: {
    HeaderTitle: string;
    Image: string;
    ContactName: string;
    ContactDescription: string;
    Email: string;
    PhoneNumber: string;
  };
}

class PRContactCard extends React.Component<PRContactCardProps, {}> {
  private contactCardElement: HTMLDivElement;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let images = this.contactCardElement.querySelectorAll("img");
    if (images && images.length) {
      objectFitImages(images);
    }
  }

  render() {
    return (
      <div className="contact-card" ref={el => (this.contactCardElement = el)}>
        {this.props.data && (
          <div className="contact-card-header">
            <span
              dangerouslySetInnerHTML={{ __html: this.props.data.HeaderTitle }}
            />
          </div>
        )}
        {this.props.data && (
          <div className="contact-card-content">
            <div className="contact-card-image-container">
              <div
                className="contact-card-image"
                dangerouslySetInnerHTML={{ __html: this.props.data.Image }}
              />
            </div>
            <div className="contact-card-name-container contact-card-section">
              <div
                className="contact-card-name"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.ContactName
                }}
              />
              <div
                className="contact-card-description"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.ContactDescription
                }}
              />
            </div>
            {this.props.data.Email && (
              <div className="contact-card-email-container contact-card-section">
                <PRIcon className="contact-card-email-icon">
                  <NewsletterIcon />
                </PRIcon>
                <div className="contact-card-email">
                  <a
                    href=""
                    dangerouslySetInnerHTML={{ __html: this.props.data.Email }}
                  />
                </div>
              </div>
            )}
            {this.props.data.PhoneNumber && (
              <div className="contact-card-phone-container contact-card-section">
                <PRIcon className="contact-card-phone-icon">
                  <PhoneIcon />
                </PRIcon>
                <div
                  className="contact-card-phone"
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.PhoneNumber
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default PRContactCard;
