import React from "react";
import Popover from "@material-ui/core/Popover";
import { PRIcon } from "../../PRIcon/PRIcon";
import Placeholder from "../../../js/extensions/Placeholder";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import PRMenuCart from "./PRMenuCart";
import "./PRMenu.scss";
import { HamburgerIcon, SearchIcon, ArrowDownIcon } from "../../PRIcon/Icons";
import { ToyotaLogo } from "../Images/Images";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

export interface PRMenuProps {
  width: Breakpoint;
  data?: {
    VehicleCategories: Array<{
      CategoryLabel: string;
      Vehicles: Array<{
        Label: string;
        Image: string;
        Url: string;
      }>;
    }>;
    PrimaryMenu: Array<{
      Label: string;
      Url?: string;
      SecondaryMenu: Array<{
        Label: string;
        Url?: string;
      }>;
    }>;
    VehicleSubMenu?: Array<{
      Label: string;
      Url?: string;
    }>;
    DownloadCartLink?: string;
  };
  placeholder?: boolean;
}

const mapStateToProps = state => ({
  items: state.items
});

class PRMenu extends React.Component<PRMenuProps, {}> {
  state = {
    anchorEl: null,
    drawer: false
  };

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
    event.currentTarget.classList.add("active");
  };

  handleClose = () => {
    this.state.anchorEl.classList.remove("active");
    this.setState({ anchorEl: null });
  };

  toggleDrawer = open => () => {
    this.setState({
      drawer: open
    });
  };

  render() {
    const { anchorEl } = this.state;

    var popovers = [];

    var menuItems = [];

    var sideMenuItems = [];

    this.props.data.PrimaryMenu.forEach((element, $index) => {
      if (this.props.width !== "xs" && this.props.width !== "sm") {
        var label = "menu-item-" + $index;

        var className = "pr-menu-item " + label;
        let menuItem = element.Url ?
          (
            <div
              key={"pr-menu-item-" + $index}
              className={className}
              tabIndex={0}
            >
              <a href={element.Url}>{element.Label}</a>
            </div>
          ) :
          (
            <div
              key={"pr-menu-item-" + $index}
              className={className}
              aria-owns={anchorEl ? "menu-item-" + $index : null}
              aria-haspopup="true"
              onClick={this.handleClick}
              tabIndex={0}
            >
              <span>{element.Label}</span>
            </div>
          );

        menuItems.push(menuItem);

        var secondaryItems = [];
        element.SecondaryMenu.forEach((secondaryItem, $index2) => {
          secondaryItems.push(
            <p key={"sub-index-" + $index + $index2}>
              <a href={secondaryItem.Url}>{secondaryItem.Label}</a>
            </p>
          );
        });
        var popover = (
          <Popover
            id="menu-item"
            className="menu-item"
            key={"menu-item-popover" + $index}
            open={anchorEl != null && anchorEl.classList.contains(label)}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <div className="vehicle-menu-container justify-content-center">
              {secondaryItems}
            </div>
          </Popover>
        );
        popovers.push(popover);
      } else {
        var subItems = [];
        element.SecondaryMenu.forEach((secondaryItem, $index2) => {
          subItems.push(
            <div
              key={"sub-index-" + $index + $index2}
              className="side-menu-subitem"
            >
              <a href={secondaryItem.Url}>{secondaryItem.Label}</a>
            </div>
          );
        });

        let menuItem = (
          <ExpansionPanel key={$index}>
            <ExpansionPanelSummary>
              <div className="side-menu-item">
                {element.Url ?
                  <a href={element.Url}>{element.Label}</a> :
                  element.Label
                }
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="side-menu-subitems">{subItems}</div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );

        sideMenuItems.push(menuItem);
      }
    });

    if (this.props.width === "sm" || this.props.width === "xs") {
      return (
        <div className="pr-menu xs">
          <div style={{ display: "none" }}>
            <Placeholder
              placeholderKey={"tp-menuactioncontainer"}
              isDynamic={true}
              placeholder={this.props.placeholder}
            >
              {this.props.children}
            </Placeholder>
          </div>
          <div className="pr-menu-container ">
            <div onClick={this.toggleDrawer(true)}>
              <PRIcon className="hamburger-icon">
                <HamburgerIcon />
              </PRIcon>
            </div>
            <div className="logo-container">
              <a href="/">
                <ToyotaLogo />
                <span>PRESSROOM</span>
              </a>
            </div>
            <div className="icon-container">
              <PRIcon className="search-icon">
                <SearchIcon />
              </PRIcon>
              <PRMenuCart data={{ DownloadCartLink: "/download" }} />
            </div>
          </div>
          <SwipeableDrawer
            className="side-menu"
            open={this.state.drawer}
            onClose={this.toggleDrawer(false)}
            onOpen={this.toggleDrawer(true)}
          >
            <div className="side-menu-container" tabIndex={0}>
              <ExpansionPanel>
                <ExpansionPanelSummary>
                  <div className="side-menu-item vehicle-item">
                    <span>Vehicles</span>
                    <PRIcon style={{ width: "16px", height: "16px" }}>
                      <ArrowDownIcon />
                    </PRIcon>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {this.props.data.VehicleCategories.map(
                    (vehicleCategory, $index) => {
                      return (
                        <div
                          key={"vehicle-category-" + $index}
                          className="vehicle-category"
                        >
                          <div className="vehicle-category-title">
                            {vehicleCategory.CategoryLabel}
                          </div>
                          <div className="vehicle-category-items">
                            {vehicleCategory.Vehicles.map((item, $index) => {
                              return (
                                <div
                                  key={"vehicle-category-item-" + $index}
                                  className="vehicle-category-item"
                                >
                                  <a href={item.Url}>{item.Label}</a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    }
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {sideMenuItems}
            </div>
          </SwipeableDrawer>
        </div>
      );
    } else {
      return (
        <div className="pr-menu">
          <div className="pr-menu-top-container row">
            <div className="pr-menu-top-container-content offset-lg-1 col-lg-10">
              <div className="logo-container">
                <div className="pr-menu-logo">
                  <a href="/">
                    <ToyotaLogo />
                  </a>
                </div>
                PRESSROOM
              </div>
              <div className="other-item-container row">
                <Placeholder
                  placeholderKey={"tp-menuactioncontainer"}
                  isDynamic={true}
                  placeholder={this.props.placeholder}
                >
                  {this.props.children}
                </Placeholder>
              </div>
            </div>
          </div>
          <div className="pr-menu-container-outer">
            <div className="pr-menu-container row">
              <div className="offset-lg-1 col-lg-10">
                <div
                  className="pr-menu-item vehicle"
                  aria-owns={anchorEl ? "vehicle-menu" : null}
                  aria-haspopup="true"
                  aria-label="Vehicles"
                  onClick={this.handleClick}
                  tabIndex={0}
                >
                  <span>Vehicles</span>
                  <PRIcon style={{ width: "17px", height: "17px" }}>
                    <ArrowDownIcon />
                  </PRIcon>
                </div>
                {menuItems}
              </div>
            </div>
          </div>
          <Popover
            id="vehicle-menu"
            open={anchorEl != null && anchorEl.classList.contains("vehicle")}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <div className="vehicle-menu-outer-container">
              <div className="vehicle-menu-container row justify-content-center">
                <div className="offset-md-1 col-md-10">
                  {this.props.data &&
                    this.props.data.VehicleCategories &&
                    this.props.data.VehicleCategories.map(
                      (vehicleCategory, $index) => {
                        return (
                          <div
                            key={"vehicle-sub-category" + $index}
                            className="vehicle-sub-category"
                          >
                            <div className="vehicle-sub-category-header">
                              {vehicleCategory.CategoryLabel}
                            </div>
                            <div className="vehicle-sub-category-content">
                              {vehicleCategory.Vehicles.map(
                                (vehicle, $index) => {
                                  return (
                                    <a
                                      key={
                                        "vehicle-sub-category-content-item-" +
                                        $index
                                      }
                                      href={vehicle.Url}
                                    >
                                      <div className="vehicle-sub-category-content-item">
                                        <img src={vehicle.Image} />
                                        <span>{vehicle.Label}</span>
                                      </div>
                                    </a>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
            <div className="vehicle-menu-footer-container">
              <div className="vehicle-menu-footer row justify-content-center">
                {this.props.data &&
                  this.props.data.VehicleSubMenu &&
                  this.props.data.VehicleSubMenu.map((subLink, $index) => {
                    if ($index == 0) {
                      return (
                        <div
                          key={"vehicle-menu-footer-link" + $index}
                          className="vehicle-menu-footer-link offset-md-1"
                        >
                          <a href={subLink.Url}>{subLink.Label}</a>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={"vehicle-menu-footer-link" + $index}
                          className="vehicle-menu-footer-link"
                        >
                          <a href={subLink.Url}>{subLink.Label}</a>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </Popover>
          {popovers}
        </div>
      );
    }
  }
}

export default withWidth()(PRMenu);
