import React from 'react';
import PRVehicle from "../../PageContent/PRTabs/PRComplexTabs";
import PRBanner from "../../PageContent/PRBanner/PRBanner";
import PRVehicleItem from "../../PageContent/PRTabs/PRComplexTab";
import moment from 'moment';
import './PREventList.scss';
import { PressRoomApi } from '../../../services/pressRoomApi';
import SearchResults from '../../SearchResults/SearchResults';
import PRPagination from '../../PageContent/PRPagination/PRPagination';

interface PREventListProps {
    data: {
        Banner: {
            BannerImage?: string;
            HeaderText: string;
            SubHeader?: string;
        }
    }
}

interface ISearchState {
    items: Array<any>;
    uiFilters: any;
}

export default class PREventList extends React.Component<PREventListProps, ISearchState> {

    private filters: any = {
        SortType: "Newest",
        DateFrom: "",
        DateTo: "",
        Page: 1,
        PerPage: 10,
        Type: ["Events"]
    };

    private totalItems: number = 0;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            uiFilters: {
                Year: 'none',
                Month: 'none'
            }
        }
    }

    componentDidMount() {
        this.setUpFilters();
    }

    getItems = async () => {
        let api = new PressRoomApi();
        let response = await api.searchMedia(this.filters);
        if (response.success) {
            this.totalItems = response.data.TotalCount;
            this.setState({ items: response.data.Results });
        }
    }

    setUpFilters() {
        var kvp = document.location.search.substr(1).split('&');

        this.onFilterChanges(kvp);
    }

    convertAndSetFilters = (filters) => {
        var dateTo, dateFrom;

        let newfilters = { ...this.filters };

        if (filters.Year && filters.Year !== 'none') {
            dateFrom = new Date(parseInt(filters.Year), 0, 1);
            dateTo = new Date(parseInt(filters.Year), 12, 0);

            if (filters.Month && filters.Month !== 'none') {
                dateFrom.setMonth(parseInt(filters.Month) - 1);
                dateTo = new Date(parseInt(filters.Year), parseInt(filters.Month), 0);
            }
            newfilters.DateTo = dateTo.toISOString();
            newfilters.DateFrom = dateFrom.toISOString();
        } else {
            dateTo = '';
            dateFrom = '';
        }

        this.filters = newfilters;
    }

    onFilterChanges = (urlString) => {
        var filters = {};

        var i = urlString.length; var x; while (i--) {
            x = urlString[i].split('=');

            if (x[0] == encodeURI('Year') || x[0] == encodeURI('Month')) {
                filters[decodeURI(x[0])] = decodeURI(x[1]);
            }
        }
        this.setState({ uiFilters: filters });
        this.convertAndSetFilters(filters);
        this.getItems();
    }

    onPageChange = (currentPage) => {
        let filters = { ...this.filters };
        filters.Page = currentPage;
        this.getItems();
    }

    getYearFilters = () => {
        let date = new Date(Date.now());
        let values = [{ Value: 'none', Name: 'Year', Selected: this.state.uiFilters.Year === 'none' }];
        for (let i = 0; i < 10; i++) {
            let year = (date.getFullYear() - i) + '';
            values.push({ Value: year, Name: year, Selected: (year === this.state.uiFilters.Year) });
        }

        return values;
    }

    getMonthFilters = () => {
        let months = moment.months();
        let values = [{ Value: 'none', Name: 'Month', Selected: this.state.uiFilters.Month === 'none' }];
        months.map((month, $index) => {
            values.push({ Value: ($index + 1) + '', Name: month, Selected: (($index + 1) + '' === this.state.uiFilters.Month) });
        });
        return values;
    }

    render() {
        let banner = {
            BannerImage: this.props.data.Banner.BannerImage
        }

        this.getMonthFilters();

        let template = {
            Header: 'Events',
            Filters: [{ Key: 'Year', Values: this.getYearFilters() }, { Key: 'Month', Values: this.getMonthFilters() }]
        }

        return (
            <div className="events-list">
                <PRBanner data={banner}></PRBanner>
                <PRVehicle data={template} options={{ noTabs: true }} onFilterChanges={this.onFilterChanges}>
                    <PRVehicleItem>
                        <div>
                            <SearchResults
                                items={this.state.items}
                                eventsOnly />
                            <PRPagination
                                onPageChange={this.onPageChange}
                                data={{ current: this.filters.Page, totalItems: this.totalItems, itemsPerPage: this.filters.PerPage }}>
                            </PRPagination>
                        </div>
                    </PRVehicleItem>
                </PRVehicle>
            </div>
        );
    }
}