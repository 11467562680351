import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import PREventCard from '../PressRelease/PREventCard/PREventCard';
import './SearchResults.scss';
import { Preview } from '../AdminPanel/Preview/Preview';
import Card from '../PageContent/Card/Card';

interface ISearchResultsProps {
    items: Array<any>;
    selectedItems?: Array<{
        Id: string;
        Url: string;
    }>;
    width?: string;
    onCardSelect: Function;

    refreshItems?: Function;
    actions?: {
        select?: boolean;
        addToCart?: boolean;
        download?: boolean;
        edit?: boolean;
        delete?: boolean;
    };
    includePreview?: boolean;
    eventsOnly?: boolean;
    noResultsMessage?: string;
}

interface ISearchResultsState {
    preview: any;
}

class SearchResults extends React.Component<ISearchResultsProps, ISearchResultsState> {
    private itemsPerRow: number = 5;

    constructor(props) {
        super(props);

        this.state = {
            preview: null
        };

        this.itemsPerRow = this.props.width === 'sm' ? 3 : this.props.width === 'xs' ? 1 : 5;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items) {
            this.setState({ preview: null });
        }
    }

    onCardClick = (item, row, col) => {
        if (this.props.includePreview) {
            this.setState((state) => {
                if (state.preview && state.preview.Id === item.Id && state.preview.row === row && state.preview.col === col) {
                    return { preview: null };
                }
                else {
                    return { preview: { Id: item.Id, row: row, col: col } };
                }
            });
        }
    }

    prevPreview = () => {
        let currentPreviewIndex = this.state.preview.row * this.itemsPerRow + this.state.preview.col;
        let prevPreviewIndex = currentPreviewIndex - 1;
        if (prevPreviewIndex < 0) {
            // go to the last item
            prevPreviewIndex = this.props.items.length - 1;
        }
        let item = this.props.items[prevPreviewIndex];
        let row = Math.ceil((prevPreviewIndex + 1) / this.itemsPerRow) - 1;
        let col = (prevPreviewIndex + 1) % this.itemsPerRow - 1
        if (col < 0) {
            // go to the last item in the row
            col = this.itemsPerRow - 1;
        }
        this.setState({ preview: { Id: item.Id, row: row, col: col } });
    }

    nextPreview = () => {
        let currentPreviewIndex = this.state.preview.row * this.itemsPerRow + this.state.preview.col;
        let nextPreviewIndex = currentPreviewIndex + 1;
        if (nextPreviewIndex > this.props.items.length - 1) {
            // go to the first item
            nextPreviewIndex = 0;
        }
        let item = this.props.items[nextPreviewIndex];
        let row = Math.ceil((nextPreviewIndex + 1) / this.itemsPerRow) - 1;
        let col = (nextPreviewIndex + 1) % this.itemsPerRow - 1
        if (col < 0) {
            // go to the last item in the row
            col = this.itemsPerRow - 1;
        }
        this.setState({ preview: { Id: item.Id, row: row, col: col } });
    }

    itemSelected = (id) => {
        if (this.state.preview && this.state.preview.Id === id) {
            return true;
        } else if (this.props.selectedItems) {
            let items = this.props.selectedItems.filter((item) => item.Id === id);
            return items.length > 0;
        }

        return false;
    }

    render() {
        let searchItems = [];

        if (this.props.eventsOnly) {
            this.props.items.forEach((item, $index) => {
                searchItems.push(<PREventCard data={item} key={"event-card" + $index}></PREventCard>);
            });
        } else {
            let rowNum = Math.ceil(this.props.items.length / this.itemsPerRow)
            for (let i = 0; i < rowNum; i++) {
                let row = [];
                for (let j = 0; j < this.itemsPerRow; j++) {
                    if ((i * this.itemsPerRow + j) < this.props.items.length) {
                        let item = this.props.items[(i * this.itemsPerRow + j)]
                        row.push(<Card key={"pr-card" + j}
                            className={this.props.includePreview ? "clickable" : ""}
                            data={{ ...item, Selected: this.itemSelected(item.Id) }}
                            onSelectionChange={this.props.onCardSelect}
                            onClick={() => { this.onCardClick(item, i, j) }}
                            actions={this.props.actions}
                            onEdit={this.props.refreshItems}
                            onDelete={this.props.refreshItems}
                        ></Card>)
                    }
                }
                searchItems.push(<div className="card-row" key={"card-row" + i}>{row}</div>);
                if (this.props.includePreview && this.state.preview && this.state.preview.row === i) {
                    let item = this.props.items[this.state.preview.row * this.itemsPerRow + this.state.preview.col];
                    if (item) {
                        let pos = 100 / this.itemsPerRow * (this.state.preview.col + 0.5);
                        searchItems.push(<Preview item={item} key={"preview" + i} onPrev={this.prevPreview} onNext={this.nextPreview} position={pos} />)
                    }
                }
            }
        }

        return (
            <div className="pr-search">
                <div className="pr-search-cards">
                    {searchItems.length > 0 ?
                        searchItems :
                        (this.props.noResultsMessage ? <h3 className="no-results">{this.props.noResultsMessage}</h3> : "")
                    }
                </div>
            </div>
        );
    }
}

export default withWidth()(SearchResults);