import React from 'react';
import './Preview.scss';
import { PRIcon } from '../../PRIcon/PRIcon';
import { ArrowIcon } from '../../PRIcon/Icons';
import PRCheckbox from '../../PageContent/Form/Fields/PRCheckbox/PRCheckbox';
import PRButton from '../../PageContent/PRButton/PRButton';
import { PressRoomApi } from '../../../services/pressRoomApi';
import { CookieHelper } from '../../../services/cookieHelper';
import { Notification } from "../../Notification/Notification";

interface IAsset {
    id: string;
    size: string;
    extension: string;
    linkUrl: string
}

interface IPreviewProps {
    item: {
        Id: string;
        Title: string;
        Copy: string;
        Image: string;
        LinkUrl: string;
        Date: string;
        Type: string;
        hrAssert: IAsset;
        lrAssert: IAsset;
    }
    onPrev: Function;
    onNext: Function;
    position: number;
}

interface IPreviewState {
    lowResChecked: boolean;
    highResChecked: boolean;
    highResDetails: string;
    lowResDetails: string;
    showNotification: boolean;
}

export class Preview extends React.Component<IPreviewProps, IPreviewState> {
    private myRef = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            lowResChecked: false,
            highResChecked: false,
            highResDetails: "",
            lowResDetails: "",
            showNotification: false
        };
    }

    getImageDetails = async (asset: IAsset) => {
        if (asset) {
            var image = new Image();
            image.src = asset.linkUrl;
            await new Promise((resolve) => {
                image.onload = () => resolve();
            });
            return image.width + "x" + image.height + " | " + this.getImageSize(asset.size) + " | " + asset.extension.toUpperCase();
        }
        return "";
    };

    getImageSize = (size) => {
        let bytes = Number(size);
        if (bytes / 1000 < 1000) {
            return Math.round(bytes / 1000 * 100) / 100 + "KB";
        }
        else {
            return Math.round(bytes / 1000000 * 100) / 100 + "MB";
        }
    };

    handlePrevClick = () => {
        this.props.onPrev();
    };

    handleNextClick = () => {
        this.props.onNext();
    };

    async componentDidMount() {
        let extraUpTop = (window.innerHeight - this.myRef.current.clientHeight) / 2;
        if (extraUpTop < 0) {
            extraUpTop = 0;
        }
        window.scrollTo(0, this.getCoords(this.myRef.current).top - extraUpTop);

        let hrDetails = await this.getImageDetails(this.props.item.hrAssert);
        let lrDetails = await this.getImageDetails(this.props.item.lrAssert);
        this.setState({ highResDetails: hrDetails, lowResDetails: lrDetails });
    }

    async componentDidUpdate(prevProps: IPreviewProps) {
        if (prevProps.item !== this.props.item) {
            let video: HTMLVideoElement = document.getElementById("video-player") as HTMLVideoElement;
            if (video) {
                video.pause();
                video.removeAttribute('src');
                video.load();
            }

            let hrDetails = await this.getImageDetails(this.props.item.hrAssert);
            let lrDetails = await this.getImageDetails(this.props.item.lrAssert);
            this.setState({ highResChecked: false, lowResChecked: false, highResDetails: hrDetails, lowResDetails: lrDetails });
        }
    }

    getCoords = (elem) => {
        var box = elem.getBoundingClientRect();

        var body = document.body;
        var docEl = document.documentElement;

        var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        var clientTop = docEl.clientTop || body.clientTop || 0;
        var clientLeft = docEl.clientLeft || body.clientLeft || 0;

        var top = box.top + scrollTop - clientTop;
        var left = box.left + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left) };
    }
    createTitleMarkup=(value) =>{
        return {
           __html: value };
     };

    render() {
        let marginLeft = "calc(" + this.props.position + "% - 9px)";
        return (
            <React.Fragment>
                <div className="preview-tip-row">
                    <div className="preview-tip" style={{ marginLeft: marginLeft }}></div>
                </div>
                <div className="preview-row" ref={this.myRef}>
                    <div className="prev-preview" onClick={this.handlePrevClick}>
                        <PRIcon>
                            <ArrowIcon />
                        </PRIcon>
                    </div>
                    <div className="preview-container">
                        {(this.props.item.Type !== "Video" || this.props.item.hrAssert.extension !== "mp4") &&
                            <div className="image" style={{ backgroundImage: "url('" + this.props.item.Image + "')" }}></div>
                        }
                        {this.props.item.Type === "Video" && this.props.item.hrAssert.extension === "mp4" &&
                            <video id="video-player" className="video" controls poster={this.props.item.Image}>
                                <source src={this.props.item.LinkUrl} type="video/mp4"></source>
                            </video>
                        }
                        <div className="preview-content">
                            <div className="date">
                                Added {this.props.item.Date}
                            </div>
                            <h3 dangerouslySetInnerHTML={this.createTitleMarkup(this.props.item.Title)} />
                            <p>{this.props.item.Copy}</p>
                            {this.props.item.Type === "Image" && this.props.item.hrAssert &&
                                <PRCheckbox
                                    label={this.state.highResDetails}
                                    checked={this.state.highResChecked}
                                    onChange={(value: boolean) => { this.setState({ highResChecked: value }); }}
                                    options={{ theme: "outline" }}>
                                </PRCheckbox>}
                            {this.props.item.Type === "Image" && this.props.item.lrAssert &&
                                <PRCheckbox
                                    label={this.state.lowResDetails}
                                    checked={this.state.lowResChecked}
                                    onChange={(value: boolean) => { this.setState({ lowResChecked: value }); }}
                                    options={{ theme: "outline" }}>
                                </PRCheckbox>}
                            <div className="preview-actions">
                                <Notification key="add-to-cart-notification"
                                    message="You added this item to your basket"
                                    open={this.state.showNotification}
                                    onClose={this.handleNotificationClose} />
                                <PRButton data={{ type: "primary" }} onClick={this.onAddToCart}>Add To Basket</PRButton>
                                <PRButton data={{ type: "primary" }} onClick={this.onDownload}>Download</PRButton>
                            </div>
                        </div>
                    </div>
                    <div className="next-preview" onClick={this.handleNextClick}>
                        <PRIcon>
                            <ArrowIcon />
                        </PRIcon>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onDownload = async () => {
        let ids: Array<string> = [];
        if (!this.state.highResChecked && !this.state.lowResChecked) {
            ids.push(this.props.item.Id);
        }
        if (this.state.highResChecked) {
            ids.push(this.props.item.Id);
        }
        if (this.state.lowResChecked) {
            ids.push(this.props.item.Id + "|" + this.props.item.lrAssert.id);
        }
        if (ids.length) {
            let api = new PressRoomApi();
            api.downloadFiles(ids);
        }
    };

    onAddToCart = () => {
        let itemsToAdd: Array<{ id: string, title: string, type: string }> = [];
        if (!this.state.highResChecked && !this.state.lowResChecked) {
            itemsToAdd.push({ id: this.props.item.Id, title: this.props.item.Title, type: this.props.item.Type });
        }
        if (this.state.highResChecked) {
            itemsToAdd.push({ id: this.props.item.Id, title: this.props.item.Title, type: this.props.item.Type });
        }
        if (this.state.lowResChecked) {
            itemsToAdd.push({ id: this.props.item.Id + "|" + this.props.item.lrAssert.id, title: this.props.item.Title, type: this.props.item.Type });
        }
        if (itemsToAdd.length) {
            let cookieHelper = new CookieHelper();
            let cookie = cookieHelper.getCookie("prcart");
            let items: Array<any> = cookie ? JSON.parse(cookie) : [];
            itemsToAdd.forEach(itemToAdd => {
                if (items.filter(item => item.id === itemToAdd.id).length === 0) {
                    items.push(itemToAdd);
                }
            });
            cookieHelper.setCookie("prcart", items);

            this.setState({ showNotification: true });
        }
    };

    handleNotificationClose = () => {
        this.setState({ showNotification: false });
    };
}