import React from "react";
import PRFilter from "../PageContent/PRFilter/PRFilter";
import { NewItem } from "./NewItem/NewItem";
import "./AdminDashboard.scss";
import PRPagination from "../PageContent/PRPagination/PRPagination";
import { PressRoomApi } from "../../services/pressRoomApi";
import SearchResults from "../SearchResults/SearchResults";
import { FilterHelper } from "../../services/filterHelper";

interface IAdminDashboardState {
  items: Array<any>;
}

export default class AdminDashboard extends React.Component<any, IAdminDashboardState> {

  private filters: any = {
    Query: "",
    SortType: "Newest",
    DateFrom: "",
    DateTo: "",
    Page: 1,
    PerPage: 20,
    Type: "PressRelease"
  };

  private urlString: Array<string> = [];

  private totalItems: number = 0;

  private filterHelper: FilterHelper;

  constructor(props: any) {
    super(props);

    this.state = {
      items: []
    };

    this.filterHelper = new FilterHelper();
  }

  componentDidMount() {
    this.setUpFilters();
    this.getItems();
  }

  getItems = async () => {
    let api = new PressRoomApi();
    let response = await api.searchAdminMedia({ ...this.filters, Type: [this.filters.Type] });
    if (response.success) {
      this.totalItems = response.data.TotalCount;
      this.setState({ items: response.data.Results });
    }
  }

  setUpFilters() {
    var kvp = window.location.search.substr(1).split("&");

    var filters = {
      Page: null,
      PerPage: null,
      SortType: null,
      Type: null
    };
    var tempFilters = {};

    var i = kvp.length;
    var x;
    while (i--) {
      x = kvp[i].split("=");

      if (
        x[0] == encodeURI("Query") ||
        x[0] == encodeURI("SortType") ||
        x[0] == encodeURI("DateFrom") ||
        x[0] == encodeURI("DateTo") ||
        x[0] == encodeURI("Page") ||
        x[0] == encodeURI("PerPage") ||
        x[0] == encodeURI("Type") ||
        x[0] == encodeURI("CategoryId")
      ) {
        filters[decodeURI(x[0])] = decodeURI(x[1]);
      }

      if (
        x[0] == encodeURI("DateFrom") ||
        x[0] == encodeURI("DateTo") ||
        x[0] == encodeURI("CategoryId") ||
        x[0] == encodeURI("Query")
      ) {
        tempFilters[decodeURI(x[0])] = decodeURI(x[1]);
      }
    }

    if (
      filters.Page === null ||
      filters.PerPage === null ||
      filters.SortType === null ||
      filters.Type === null
    ) {
      if (filters.Page === null) {
        filters.Page = 1;
        kvp = this.filterHelper.addToUrlString(kvp, "Page", 1);
      }

      if (filters.PerPage === null) {
        filters.PerPage = 20;
        kvp = this.filterHelper.addToUrlString(kvp, "PerPage", 20);
      }

      if (filters.SortType === null) {
        filters.SortType = "Newest";
        kvp = this.filterHelper.addToUrlString(kvp, "SortType", "Newest");
      }

      if (filters.Type === null) {
        filters.Type = "PressRelease";
        kvp = this.filterHelper.addToUrlString(kvp, "Type", "PressRelease");
      }

      this.filterHelper.applyUrlStringToUrl(kvp, filters);
    }

    if (typeof filters.Page === "string") {
      filters.Page = parseInt(filters.Page);
    }

    if (typeof filters.PerPage === "string") {
      filters.PerPage = parseInt(filters.PerPage);
    }

    this.urlString = kvp;
    this.filters = filters;
  }

  onFilterChange = (filters: any) => {
    let urlString = this.urlString;

    for (var filterKey in filters) {
      if (filters.hasOwnProperty(filterKey)) {
        urlString = this.filterHelper.addToUrlString(
          urlString,
          filterKey,
          filters[filterKey]
        );
      }
    }
    this.filters = filters;
    this.urlString = urlString;

    this.filterHelper.applyUrlStringToUrl(urlString, this.filters);
    this.getItems();
  };

  onPageChange = currentPage => {
    let filters = { ...this.filters };
    filters.Page = currentPage;
    this.onFilterChange(filters);
  };

  // We can't be too quick or we will beat the solr index
  getItemsDelayed = () => {
    setTimeout(() => this.getItems(), 1000);
  }

  render() {
    return (
      <div className="media-library-container">
        <PRFilter
          label="Admin Dashboard"
          onFilterChange={this.onFilterChange}
          filters={this.filters}
          showPaginationOptions
          showTypes
        />

        <div className="media-library-inner-container">
          <NewItem type={this.filters.Type} onItemCreated={this.getItemsDelayed} />
        </div>

        <SearchResults
          items={this.state.items}
          refreshItems={this.getItemsDelayed}
          actions={{ edit: true, delete: true }}
        />

        <PRPagination
          onPageChange={this.onPageChange}
          data={{ current: this.filters.Page, totalItems: this.totalItems, itemsPerPage: this.filters.PerPage }}>
        </PRPagination>
      </div>
    );
  }
}
