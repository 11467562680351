import React from "react";
import ReactSwipeEvents from "react-swipe-events";
import "./PRSelectSlider.scss";

export interface PRSelectSliderProps {
  data?: {
    Values?: Array<{
      Value: any;
      Name: string;
      Selected?: boolean;
    }>;
  };

  onChange: Function;
}

interface PRSelectSliderState {
  scrollAmount: number;
  selected: number;
  offset: number;
  swiping: boolean;
  translate: number;
}

class PRSelectSlider extends React.Component<PRSelectSliderProps, PRSelectSliderState> {
  constructor(props) {
    super(props);

    this.state = {
      scrollAmount: 1,
      selected: 1,
      offset: 0,
      swiping: false,
      translate: 0
    };
  }

  node = null;

  swipeLeft = (e, originalX, endX) => { };

  getTransformValue = () => {
    if (this.node) {
      let width = ((this.node as HTMLElement).clientWidth * 33.33) / 100;
      let container = (this.node as HTMLElement).getElementsByClassName(
        "select-slider-inner-container"
      )[0] as HTMLElement;
      if (this.state.selected === 0) {
        container.style.transform = "translateX(" + width + "px)";
        return width;
      } else if (this.state.selected === 1) {
        container.style.transform = "translateX(" + 0 + "px)";
        return 0;
      } else {
        container.style.transform =
          "translateX(-" + (this.state.selected - 1) * width + "px)";
        return -(this.state.selected - 1) * width;
      }
    }
  };

  swiping = (e, originalX, originalY, currentX, currentY, deltaX, deltaY) => {
    let itemWidth = (this.node as HTMLElement).clientWidth * 0.3333;
    let translate = this.state.translate + deltaX;
    if (translate > itemWidth) {
      translate = itemWidth;
    } else if (translate < (this.props.data.Values.length - 2) * -itemWidth) {
      translate = (this.props.data.Values.length - 2) * -itemWidth;
    }
    let selected =
      Math.abs(Math.floor(translate / itemWidth)) +
      (Math.abs(translate % itemWidth) > itemWidth * 0.5 ? 1 : 0);
    if (translate > itemWidth * 0.5) {
      selected = 0;
    } else if (translate >= 0) {
      selected = 1;
    }
    ((this.node as HTMLElement).getElementsByClassName(
      "select-slider-inner-container"
    )[0] as HTMLElement).style.transform = "translateX(" + translate + "px)";
    if (selected !== this.state.selected) {
      this.setState({ selected: selected });
      this.props.onChange(
        this.props.data.Values[selected].Name,
        this.props.data.Values[selected].Value
      );
    }
  };

  swiped = (e, originalX, originalY, currentX, currentY, deltaX, deltaY) => {
    let itemWidth = ((this.node as HTMLElement).clientWidth * 22) / 100;
    let selectedValue = Math.abs(Math.floor(deltaX / itemWidth));
    this.setState({ translate: this.getTransformValue() });
  };

  componentDidMount() {
    this.props.data.Values.map((value, $index) => {
      if (value.Selected) {
        this.setState({ selected: $index, scrollAmount: $index });
      }
    });
    this.getTransformValue();
  }

  render() {
    return (
      <div className="select-slider" ref={node => (this.node = node)}>
        <ReactSwipeEvents
          onSwipedLeft={this.swipeLeft}
          onSwiping={this.swiping}
          onSwiped={this.swiped}
        >
          <div className="select-slider-container">
            <div className="select-slider-inner-container">
              {this.props.data &&
                this.props.data.Values &&
                this.props.data.Values.map((item, $index) => {
                  let className = "slider-item";
                  if (this.state.selected === $index) {
                    className += " selected";
                  }
                  return (
                    <div key={$index} className={className}>
                      {item.Name}
                    </div>
                  );
                })}
            </div>
          </div>
        </ReactSwipeEvents>
      </div>
    );
  }
}

export default PRSelectSlider;
