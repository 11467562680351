import React from "react";
import "./Card.scss";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import { CardActions } from "../../AdminPanel/CardActions/CardActions";
import PRButton from "../PRButton/PRButton";
import utils from "../../../services/utils";

interface ICardProps {
    data: {
        Id?: string;
        Image?: string;
        Date?: string;
        Title?: string;
        Copy?: string;
        LinkUrl?: string;
        Type?: string;
        VideoUrl?: string;
        Selected?: boolean;
        Published?: boolean;
    };
    width: string;
    onSelectionChange?: Function;
    onClick?: Function;
    actions?: {
        select?: boolean;
        addToCart?: boolean;
        download?: boolean;
        edit?: boolean;
        delete?: boolean;
    };
    onEdit: Function;
    onDelete: Function;
    hideContent?: boolean;
    className?: string;
}

interface ICardState {
    selected: boolean;
}

class Card extends React.Component<ICardProps, ICardState> {
    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.data.Selected
        };
    }

    static propTypes = {
        width: PropTypes.string.isRequired
    };

    componentDidUpdate(prevProps) {
        if (this.props.data.Selected !== prevProps.data.Selected) {
            this.setState({ selected: this.props.data.Selected });
        }
    }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    createTitleMarkup=(value) =>{
        return {
           __html: value };
     };
    render() {
        let classname =
            "card-container " +
            this.props.width +
            (this.props.data.Selected ? " selected" : "") +
            (this.props.className ? " " + this.props.className : "");
        return (
            <div className={classname} onClick={this.onClick}>
                {this.props.actions && (
                    <CardActions
                        actions={this.props.actions}
                        item={this.props.data}
                        onEdit={this.props.onEdit}
                        onDelete={this.props.onDelete}
                        onSelectionChange={this.props.onSelectionChange}
                    />
                )}
                <div className="card-image-container">
                    <div
                        className="image"
                        style={{ backgroundImage: "url('" + (this.props.data.Image || utils.getEmptyImage()) + "')" }}
                    />
                </div>
                {!this.props.hideContent && (
                    <React.Fragment>
                        <div className="card-date">{this.props.data.Date}</div>
                        <div className="card-copy-container">
                            {this.props.data.Type !== "Image" && this.props.data.Type !== "Video" && this.props.data.Published ? (
                                <a href={this.props.data.LinkUrl}> 
                                <h3 className="card-title"  dangerouslySetInnerHTML={this.createTitleMarkup(this.props.data.Title)} /> 
                                </a>
                            ) : (
                                <h3 className="card-title"  dangerouslySetInnerHTML={this.createTitleMarkup(this.props.data.Title)} />                              
                            )}

                            <p className="card-copy" dangerouslySetInnerHTML={{ __html: this.props.data.Copy }} />
                            {this.props.width !== "xs" &&
                                this.props.data.Type !== "Image" &&
                                this.props.data.Type !== "Video" &&
                                this.props.data.Published && (
                                    <PRButton
                                        data={{ type: "tertiary", link: this.props.data.LinkUrl, newtab: this.props.actions.edit ? true : false }}
                                    >
                                        Read More
                                    </PRButton>
                                )}
                  {this.props.width !== "xs" &&
                    this.props.data.Type !== "Image" &&
                    this.props.data.Type !== "Video" &&
                    !this.props.data.Published && (
                      <PRButton data={{ type: "tertiary", link: this.props.data.LinkUrl, newtab: true }}>
                        Preview Draft
                      </PRButton>
                    )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default withWidth()(Card);
