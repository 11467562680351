export default class Constants {
    static readonly MediaFolders = {
        Contacts: "{82C21483-62B3-4028-A379-C8F29E14C5A0}",
        Thumbnails: "{5F8691E0-D676-4DE6-84E6-10803DB438DD}",
        Banners: "{F1364609-A83C-4EF3-BF81-E47D6A0F5172}",
        PressRoomImages: "{49D38FD1-8564-4DE4-A30E-163FFCF13421}",
        PressRoomVideos: "{3979C7CE-1E4C-41A4-BAAE-E4F745C16AEB}",
        Other: "{47D8C1FA-85C8-4F71-9777-1CD70FF588C7}"
    }

    static readonly DateFormat = "DD-MM-YYYY";
    static readonly TimeStampFormat = "h:mm:ss A DD-MM-YYYY";
    static readonly ImageDimensions = {
        Banner: { width: 1600, height: 587 },
        Thumbnail: { width: 629, height: 350 },
        Contact: { width: 262, height: 262 }
    }

    static readonly Links = {
        PressReleaseForm: "/admin-dashboard/admin-pressrelease",
        PressKitForm: "/admin-dashboard/admin-presskit",
        PressReleaseDashboard: "/admin-dashboard/?Type=PressRelease",
        PressKitDashboard: "/admin-dashboard/?Type=PressKit",
        PressReleasePreview: "/PressRelease?pr-code="
    };

    static readonly StoragTypes = {
        Sitecore: "52681552-9321-4763-8DB5-B51498B611A5",
        Aprimo: "F47DEFA2-73DF-43E6-AB72-12D609001851"
    }

    constructor() {
    }
}