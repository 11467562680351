import React from "react";
import Dropzone from "react-dropzone";
import PRButton from "../../../PRButton/PRButton";
import "./FileDrop.scss";

interface IFileDropProps {
    onChange?: Function;
    value?: any;
    type?: string;
    label?: string;
    name?: string;
    required?: boolean;
    error?: boolean;
    canBeCleared?: boolean;
    extension?: string;
}

interface IFileDropState {
    file: any;
    error: boolean;
}

export default class FileDrop extends React.Component<IFileDropProps, IFileDropState> {
    private dropzoneRef: any = null;

    constructor(props: IFileDropProps) {
        super(props);

        this.state = {
            file: this.props.value ? { preview: this.props.value, type: this.props.extension } : null,
            error: this.props.error
        };

        console.log("File Drop State", this.state);

        this.dropzoneRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        // If the error value has been changed by the parent, update the state.
        if (prevProps.error !== this.props.error) {
            this.setState({ error: this.props.error });
        }
    }

    getFileType = () => {
        if (this.props.type === "image") {
            return "image/*";
        } else if (this.props.type === "video") {
            return "video/*";
        } else {
            return "image/*, video/*";
        }
    };

    openDialog = () => {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    };

    removeFile = () => {
        this.setState({ file: null });
        this.props.onChange(null, this.props.name); // Need this so that the form has the new value
    };

    render() {
        return (
            <div className={"simple-image-upload" + (this.props.required ? " required" : "") + (this.props.error ? " error" : "")}>
                {this.props.required && <span className="required">*</span>}
                <label>{this.props.label}</label>
                {this.state.error && <span className="error-text">{!this.state.file ? "Required" : "Invalid"}</span>}
                <div className="file-upload">
                    <Dropzone
                        ref={this.dropzoneRef}
                        onDrop={this.onFileDrop}
                        accept={this.getFileType()}
                        multiple={false}
                        className="dropzone-control"
                        inputProps={{ required: this.props.required && !this.state.file, name: this.props.name }}
                    >
                        <div className="file-upload-dropzone">
                            {this.state.file && this.props.type === "image" && (
                                <div>
                                    <img className="image" src={this.state.file.preview} />
                                </div>
                            )}
                            {this.state.file && this.props.type === "video" && (
                                <div>
                                    {this.state.file.type === "video/mp4" ? (
                                        <video className="video">
                                            <source src={this.state.file.preview} type="video/mp4"></source>
                                        </video>
                                    ) : (
                                        "Preview will not be available for this file format. Please upload .mp4 if you want preview to be available."
                                    )}
                                </div>
                            )}
                        </div>
                    </Dropzone>
                    <div className="file-upload-actions">
                        <PRButton data={{ type: "primary" }} onClick={this.openDialog}>
                            Select {this.props.type}
                        </PRButton>
                        {this.props.canBeCleared && (
                            <PRButton data={{ type: "primary" }} onClick={this.removeFile}>
                                Remove {this.props.type}
                            </PRButton>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    onFileDrop = files => {
        this.setState({ file: files[0] });
        this.props.onChange(files[0], this.props.name); // Need this so that the form has the new value
    };
}
