import React from "react";
import "./PRCheckbox.scss";

interface PRCheckboxProps {
  label?: string;
  checked?: boolean;
  onChange?: Function;
  index?: number;
  required?: boolean;
  error?: boolean;
  options?: {
    theme?: string;
  };
  name?: string;
}

interface PRCheckboxState {
  checked: boolean;
  error: boolean;
}

export default class PRCheckbox extends React.Component<PRCheckboxProps, PRCheckboxState> {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked,
      error: this.props.error
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({ checked: this.props.checked });
    }
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }

  onClick = () => {
    let value = !this.state.checked;
    this.setState({ checked: value, error: !value && this.props.required });
    this.props.onChange(value, this.props.index);
  };

  render() {
    let checkboxClass =
      "pr-checkbox" +
      (this.props.options ? " " + this.props.options.theme : "") +
      (this.props.required ? " required" : "");
    return (
      <div className={checkboxClass}>
        <div>
          {this.props.required && <span className="required-icon">*</span>}
          <label
            className={
              (this.state.checked ? "checked" : "") +
              (this.state.error ? " error" : "")
            }
          >
            <input
              type="checkbox"
              required={this.props.required}
              checked={this.state.checked}
              onChange={this.onClick}
              name={this.props.name}
            />
            <span className="checkmark" />
            <span className="label">
              {this.props.children ? this.props.children : this.props.label}
            </span>
          </label>
        </div>
        {this.state.error && <span className="error-label">Required</span>}
      </div>
    );
  }
}
