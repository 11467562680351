import * as React from "react";

export class FacebookIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M40.24,21V15.34C40.24,12.65,42,12,43.32,12h7.86V0H40.36c-12,0-14.74,8.89-14.74,14.66V21h-7V35h7.06V70h14V35H50.08l.45-5.51.84-8.5Z" />
      </svg>
    );
  }
}

export class InstagramIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M17.12,35A17.88,17.88,0,1,1,35,52.88,17.9,17.9,0,0,1,17.12,35ZM53.46,0H16.54A16.58,16.58,0,0,0,0,16.54V53.46A16.61,16.61,0,0,0,16.54,70H53.46A16.58,16.58,0,0,0,70,53.46V16.54A16.56,16.56,0,0,0,53.46,0ZM16.54,4.14H53.46a12.41,12.41,0,0,1,12.4,12.4V53.46a12.41,12.41,0,0,1-12.4,12.4H16.54a12.41,12.41,0,0,1-12.4-12.4V16.54a12.41,12.41,0,0,1,12.4-12.4Zm37,9.31a3.64,3.64,0,1,0,3.63,3.63,3.63,3.63,0,0,0-3.63-3.63ZM49.31,35A14.33,14.33,0,1,0,35,49.31,14.33,14.33,0,0,0,49.31,35Z" />
      </svg>
    );
  }
}

export class YoutubeIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M69.32,20.41s-.72-5-2.81-7.24a10,10,0,0,0-7-3.12c-9.79-.72-24.47-.72-24.47-.72H35s-14.64,0-24.43.72a10,10,0,0,0-7,3.12C1.44,15.37.72,20.41.72,20.41A111.89,111.89,0,0,0,0,32.17v5.58A109.9,109.9,0,0,0,.72,49.51s.72,5.05,2.81,7.29c2.66,2.92,6.15,2.8,7.74,3.11,5.58.57,23.79.76,23.79.76s14.68-.08,24.47-.76a9.84,9.84,0,0,0,7-3.11c2.09-2.24,2.81-7.29,2.81-7.29A116.27,116.27,0,0,0,70,37.75V32.17C70,26.33,69.32,20.41,69.32,20.41Zm-41.55,24V23.94l8.58,4.67,10.36,5.61Z" />
      </svg>
    );
  }
}

export class TwitterIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 50 50" version="1.1">
        <path
          d="M49.8255415,9.82905604 C48.0280375,10.606784 46.0726071,11.1432576 44.0084535,11.4107008 C46.1257783,10.1512576 47.7336119,8.22122242 48.4859447,5.86184961 C46.5281335,7.01495042 44.3306551,7.84584962 42.025247,8.30137603 C40.199967,6.34515201 37.5485494,5.1380864 34.5987382,5.1380864 C29.0229045,5.1380864 24.4644661,9.61478404 24.4644661,15.1104641 C24.4644661,15.9151745 24.6001717,16.6659201 24.7604789,17.3896833 C16.3165748,16.9881217 8.83610119,13.0201217 3.84911875,6.96098562 C2.99203074,8.46247683 2.45635074,10.1512576 2.45635074,11.9741569 C2.45635074,15.4596481 4.27924995,18.5150081 6.95923717,20.2855297 C5.29823236,20.2585473 3.74277635,19.8014337 2.37619714,19.0514817 L2.37619714,19.1856001 C2.37619714,20.4720257 2.61745154,21.6521089 3.07218434,22.7774337 C4.30702595,25.9153282 7.12113157,28.2731138 10.5264692,28.9437058 C9.64001799,29.1857538 8.78292999,29.3190786 7.84568838,29.3190786 C7.20207878,29.3190786 6.55767557,29.2381314 5.94025477,29.1048066 C7.25524998,33.072013 10.981202,35.9670658 15.405522,36.0742018 C11.9192372,38.7549827 7.57665798,40.3366275 2.83172354,40.3366275 C2.00003074,40.3366275 1.22388993,40.2826627 0.392990723,40.2025091 C4.89587716,43.0182019 10.2304564,44.6800003 15.941202,44.6800003 C31.7846326,44.6800003 41.568927,33.714829 44.1147959,22.3211137 C44.5719095,20.2855297 44.7845943,18.2745473 44.7845943,16.2905473 L44.7845943,15.0041217 C46.7424055,13.5819905 48.4859447,11.8130561 49.8255415,9.82905604"
          id="path-1"
        />
      </svg>
    );
  }
}

export class NewsletterIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M0,11.78V58.22H70V11.78Zm65.87,2.06L35.17,33.4,4.57,13.84ZM2.07,56.16V14.7l33.1,21.16L67.93,15V56.16Z" />
      </svg>
    );
  }
}

export class ArrowIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <polygon points="21.97 58.8 20.78 57.44 46.48 34.94 20.99 12.56 22.18 11.2 49.22 34.94 21.97 58.8" />
      </svg>
    );
  }
}

export class ArrowDownIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <title>toyotaIcon_arrowDown</title>
        <polygon points="35.09 50.67 0 21.09 1.49 19.33 35.09 47.66 68.52 19.55 70 21.32 35.09 50.67" />
      </svg>
    );
  }
}

export class SearchIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M69.94,67.66,51.46,49.77a29.63,29.63,0,1,0-1.75,1.78l18.5,17.91ZM2.14,30a27.5,27.5,0,1,1,27.5,27.49A27.53,27.53,0,0,1,2.14,30Z" />
      </svg>
    );
  }
}

export class HamburgerIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
      >
        <path d="M4 10h24c1.104 0 2-.896 2-2s-.896-2-2-2H4c-1.104 0-2 .896-2 2s.896 2 2 2zm24 4H4c-1.104 0-2 .896-2 2s.896 2 2 2h24c1.104 0 2-.896 2-2s-.896-2-2-2zm0 8H4c-1.104 0-2 .896-2 2s.896 2 2 2h24c1.104 0 2-.896 2-2s-.896-2-2-2z" />
      </svg>
    );
  }
}

export class DownloadIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M68.07,14.9V39.82A14.82,14.82,0,0,1,53.25,54.64H16.75A14.82,14.82,0,0,1,1.93,39.81V14.9a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1V39.82A16.75,16.75,0,0,0,16.75,56.57h36.5A16.75,16.75,0,0,0,70,39.81V14.9a1,1,0,0,0-1-1h0A1,1,0,0,0,68.07,14.9Z" />
        <polygon points="35 41.74 22.62 29.35 23.92 28.05 35 39.13 46.08 28.05 47.38 29.35 35 41.74" />
      </svg>
    );
  }
}

export class PlusIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <polygon points="36.48 0 33.52 0 33.52 33.52 0 33.52 0 36.48 33.52 36.48 33.52 70 36.48 70 36.48 36.48 70 36.48 70 33.52 36.48 33.52 36.48 0" />
      </svg>
    );
  }
}

export class BagIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M48.92,12.42C48.36,5.23,42.34.54,35,.54S21.64,5.23,21.08,12.42H0v46H70v-46ZM35,2.74c6.12,0,11.17,3.7,11.72,9.68H23.28C23.83,6.44,28.88,2.74,35,2.74Zm32.8,53.5H2.2V14.62H67.8Z" />
      </svg>
    );
  }
}

export class ImageIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M0,9.84V60.16H70V9.84ZM33.3,35,2,57.27V12.73ZM4.07,11.81H65.93L35,33.79ZM35,36.21l30.93,22H4.07ZM36.7,35,68,12.73V57.27Z" />
      </svg>
    );
  }
}

export class DocumentIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M11.73,0V70H58.27V0ZM56.62,68.35H13.38V1.65H56.62Z" />
        <rect x="20.04" y="13.95" width="29.93" height="1.65" />
        <rect x="20.04" y="22.04" width="29.93" height="1.65" />
        <rect x="20.04" y="30.13" width="29.93" height="1.65" />
        <rect x="20.04" y="38.22" width="29.93" height="1.65" />
        <rect x="20.04" y="46.31" width="29.93" height="1.65" />
        <rect x="20.04" y="54.4" width="29.93" height="1.65" />
      </svg>
    );
  }
}

export class CloseIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 70 70" version="1.1">
        <polygon
          id="Fill-1"
          transform="translate(35.000000, 35.000000) rotate(45.000000) translate(-35.000000, -35.000000) "
          points="33.1326656 -9 33.1326656 33.1326656 -9 33.1326656 -9 36.8673344 33.1326656 36.8673344 33.1326656 79 36.8673344 79 36.8673344 36.8673344 79 36.8673344 79 33.1326656 36.8673344 33.1326656 36.8673344 -9"
        />
      </svg>
    );
  }
}

export class VideoIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 56 56" version="1.1">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons" transform="translate(-87.000000, -4261.000000)">
            <rect x="0" y="0" width="780" height="5448"></rect>
          </g>
          <g id="icons-/-video" fill="#9b9b9b">
            <path d="M0,48 L56,48 L56,8 L0,8 L0,48 Z M2,46 L54,46 L54,10 L2,10 L2,46 Z" id="Fill-1"></path>
            <path d="M20,17.464 L37.852,27.771 L20,38.078 L20,17.464 Z M18,14 L18,41.542 L41.852,27.771 L18,14 Z" id="Fill-2"></path>
          </g>
        </g>
      </svg>
    );
  }
}

export class PenIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path
          d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 L20.71,7.04 Z"
          id="path-1"
        />
      </svg>
    );
  }
}

export class LoctionIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M35,70c-5.72,0-7.77-5.91-7.86-6.16L13.85,32.57a22.86,22.86,0,0,1-2-9.41,23.16,23.16,0,1,1,44.64,8.65h0l-.33.77L42.83,63.91C42.77,64.1,40.64,70,35,70ZM35,2a21.17,21.17,0,0,0-19.33,29.8L29,63.13c.09.27,1.72,4.89,6,4.89s5.91-4.63,6-4.83l13.35-31.4.31-.73a21,21,0,0,0,1.53-7.9A21.2,21.2,0,0,0,35,2Z" />
        <path d="M35,32.69a8.3,8.3,0,1,1,8.3-8.3A8.31,8.31,0,0,1,35,32.69Zm0-15a6.69,6.69,0,1,0,6.69,6.68A6.69,6.69,0,0,0,35,17.71Z" />
      </svg>
    );
  }
}

export class PhoneIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M38.33,61.92H31.67a.82.82,0,0,1,0-1.64h6.66a.82.82,0,0,1,0,1.64Z" />
        <path d="M44.26.75H25.74A10.64,10.64,0,0,0,15.11,11.38V59.12A10.64,10.64,0,0,0,25.74,69.75H44.26A10.64,10.64,0,0,0,54.89,59.12V11.38A10.64,10.64,0,0,0,44.26.75ZM25.74,2.39H44.26a9,9,0,0,1,9,9V13H16.75V11.38A9,9,0,0,1,25.74,2.39ZM53.25,14.6V54.73H16.75V14.6Zm-9,53.51H25.74a9,9,0,0,1-9-9V56.37h36.5v2.75A9,9,0,0,1,44.26,68.11Z" />
        <circle cx="35" cy="7.76" r="1.35" />
      </svg>
    );
  }
}

export class LinkShareIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M61.94,14.5a11.82,11.82,0,0,0-3.47-8.42L55.86,3.47A11.94,11.94,0,0,0,39,3.47L22.37,20.14A11.88,11.88,0,0,0,22,36.59l-10.5,10.5a11.94,11.94,0,0,0,0,16.83l2.61,2.61a11.94,11.94,0,0,0,16.83,0L47.63,49.86A11.88,11.88,0,0,0,48,33.41l10.5-10.5A11.81,11.81,0,0,0,61.94,14.5Zm-12.48,27a10.17,10.17,0,0,1-3,7.25L29.81,65.37a10.3,10.3,0,0,1-14.51,0l-2.61-2.61a10.3,10.3,0,0,1,0-14.51L23.17,37.77,25,39.57a11.9,11.9,0,0,0,16.82,0l5-5A10.15,10.15,0,0,1,49.46,41.45ZM24.33,36.61l5-5a10.26,10.26,0,0,1,14.5,0l1.81,1.8-5,5a10.26,10.26,0,0,1-14.5,0Zm22.5-4.38L45,30.43a11.9,11.9,0,0,0-16.82,0l-5,5a10.25,10.25,0,0,1,.34-14.13L40.19,4.63a10.3,10.3,0,0,1,14.51,0l2.61,2.61a10.3,10.3,0,0,1,0,14.51Z" />
      </svg>
    );
  }
}

export class WebIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 56 56" version="1.1">
        <path
          d="M28,56 C12.536027,56 0,43.463973 0,28 C0,12.536027 12.536027,0 28,0 C43.463973,0 56,12.536027 56,28 C56,43.463973 43.463973,56 28,56 Z M28,54 C42.3594035,54 54,42.3594035 54,28 C54,13.6405965 42.3594035,2 28,2 C13.6405965,2 2,13.6405965 2,28 C2,42.3594035 13.6405965,54 28,54 Z M28,55 C19.0161563,55 12,42.8386623 12,28 C12,13.1613377 19.0161563,1 28,1 C36.9838437,1 44,13.1613377 44,28 C44,42.8386623 36.9838437,55 28,55 Z M28,53 C35.5846988,53 42,41.8801446 42,28 C42,14.1198554 35.5846988,3 28,3 C20.4153012,3 14,14.1198554 14,28 C14,41.8801446 20.4153012,53 28,53 Z M27,1 L29,1 L29,54 L27,54 L27,1 Z M52,39 L52,41 L4,41 L4,39 L52,39 Z M55,26.5 L55,28.5 L1,28.5 L1,26.5 L55,26.5 Z M51.684615,14 L51.684615,16 L5,16 L5,14 L51.684615,14 Z"
          id="Combined-Shape"
        />
      </svg>
    );
  }
}

export class PlayIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 70 70" version="1.1">
        <polygon
          id="Triangle-2"
          transform="translate(34.000000, 35.000000) rotate(90.000000) translate(-34.000000, -35.000000) "
          points="34 1 68 69 0 69"
        />
      </svg>
    );
  }
}

export class DeleteIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    );
  }
}

export class PictureIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z" />
      </svg>
    );
  }
}

export class VideoCamIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z" />
      </svg>
    );
  }
}

export class BookIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 70 70" version="1.1">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons" transform="translate(-87.000000, -3921.000000)">
            <rect x="0" y="0" width="780" height="5448"></rect>
          </g>
          <g id="icons-/-press" fill="#9b9b9b">
            <path d="M0,59.126 L69.126,59.126 L69.126,10 L0,10 L0,59.126 Z M35.563,57.126 L67.126,57.126 L67.126,12 L35.563,12 L35.563,57.126 Z M2,57.126 L33.563,57.126 L33.563,12 L2,12 L2,57.126 Z M41.628,23.397 L61.191,23.397 L61.191,21.397 L41.628,21.397 L41.628,23.397 Z M41.628,31.897 L61.191,31.897 L61.191,29.897 L41.628,29.897 L41.628,31.897 Z M41.628,40.397 L61.191,40.397 L61.191,38.397 L41.628,38.397 L41.628,40.397 Z M41.628,48.897 L61.191,48.897 L61.191,46.897 L41.628,46.897 L41.628,48.897 Z M7.628,23.397 L27.191,23.397 L27.191,21.397 L7.628,21.397 L7.628,23.397 Z M7.628,31.897 L27.191,31.897 L27.191,29.897 L7.628,29.897 L7.628,31.897 Z M7.628,40.397 L27.191,40.397 L27.191,38.397 L7.628,38.397 L7.628,40.397 Z M7.628,48.897 L27.191,48.897 L27.191,46.897 L7.628,46.897 L7.628,48.897 Z" id="Fill-1"></path>
          </g>
        </g>
      </svg>
    );
  }
}

export class DescriptionIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z" />
      </svg>
    );
  }
}

export class CalendarIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 56 56" version="1.1">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons" transform="translate(-87.000000, -1729.000000)">
            <rect x="0" y="0" width="780" height="5448"></rect>
          </g>
          <g id="calendar-icon-g" fill="#FFFFFF">
            <path d="M2,50 L54,50 L54,6 L2,6 L2,50 Z M4,18 L52,18 L52,8 L4,8 L4,18 Z M4,28 L12,28 L12,20 L4,20 L4,28 Z M4,38 L12,38 L12,30 L4,30 L4,38 Z M4,48 L12,48 L12,40 L4,40 L4,48 Z M14,28 L22,28 L22,20 L14,20 L14,28 Z M14,38 L22,38 L22,30 L14,30 L14,38 Z M14,48 L22,48 L22,40 L14,40 L14,48 Z M24,28 L32,28 L32,20 L24,20 L24,28 Z M24,38 L32,38 L32,30 L24,30 L24,38 Z M24,48 L32,48 L32,40 L24,40 L24,48 Z M34,28 L42,28 L42,20 L34,20 L34,28 Z M34,38 L42,38 L42,30 L34,30 L34,38 Z M34,48 L42,48 L42,40 L34,40 L34,48 Z M44,28 L52,28 L52,20 L44,20 L44,28 Z M44,38 L52,38 L52,30 L44,30 L44,38 Z M44,48 L52,48 L52,40 L44,40 L44,48 Z" id="Fill-1"></path>
          </g>
        </g>
      </svg>
    );
  }
}