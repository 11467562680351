import React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import withWidth from "@material-ui/core/withWidth";
import "./PRPagination.scss";
import { ArrowIcon } from "../../PRIcon/Icons";

interface PRPaginationProps {
  data?: {
    current: number;
    totalItems: number;
    itemsPerPage: number;
  };
  width?: string;
  onPageChange: Function;
}

interface PRPaginationState {
  currentPage: number;
}

class PRPagination extends React.Component<PRPaginationProps, PRPaginationState> {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: this.props.data.current
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.current !== this.props.data.current) {
      this.setState({ currentPage: this.props.data.current });
    }
  }

  getPagenumber = number => {
    return number < 10 ? "0" + number : "" + number;
  };

  onPageChange = number => {
    let num = number;
    if (num < 1) {
      num = 1;
    } else if (
      num > Math.ceil(this.props.data.totalItems / this.props.data.itemsPerPage)
    ) {
      num = Math.ceil(
        this.props.data.totalItems / this.props.data.itemsPerPage
      );
    }
    this.setState({ currentPage: num });
    this.props.onPageChange(num);
  };

  render() {
    // Don't show pagination if there aren't even any items.
    if (!this.props.data.totalItems) {
      return null;
    }

    let current = this.state.currentPage;
    let totalItems = this.props.data.totalItems;
    let itemsPerPage = this.props.data.itemsPerPage;

    let totalPages = Math.ceil(totalItems / itemsPerPage);
    let pages = [{ pageNumber: 1, current: current === 1, disabled: false }];
    let pageNumber = 2;
    let num = this.props.width === "xs" ? 2 : 3;

    if (totalPages > 1) {
      if (current < num + 1 && pageNumber < num + 1) {
        for (let i = 0; i < Math.min(num, totalPages - 1); i++) {
          let page = {
            pageNumber: pageNumber,
            current: current === pageNumber,
            disabled: false
          };
          pageNumber = pageNumber + 1;
          pages.push(page);
        }
        if (totalPages > num) {
          pages.push({ pageNumber: -1, current: false, disabled: true });
        }
      } else if (current > totalPages - num) {
        pageNumber = totalPages - num;
        pages.push({ pageNumber: -1, current: false, disabled: true });
        for (let i = 0; i < num; i++) {
          let page = {
            pageNumber: pageNumber,
            current: current === pageNumber,
            disabled: false
          };
          pageNumber = pageNumber + 1;
          pages.push(page);
        }
      } else if (current >= num + 1 && current <= totalPages - num) {
        pages.push({ pageNumber: -1, current: false, disabled: true });
        pageNumber = current;
        pages.push({ pageNumber: pageNumber, current: true, disabled: false });
        if (this.props.width !== "xs") {
          pages.push({
            pageNumber: pageNumber + 1,
            current: false,
            disabled: false
          });
        }
        pages.push({ pageNumber: -1, current: false, disabled: true });
      }

      if (totalPages > num) {
        pages.push({
          pageNumber: totalPages,
          current: current === totalPages,
          disabled: false
        });
      }
    }

    return (
      <div className="pr-pagination">
        <div className="pr-pagination-container">
          {
            <div className="prev-icon-container">
              <button
                onClick={() => {
                  this.onPageChange(1);
                }}
              >
                <PRIcon className="prev">
                  <ArrowIcon />
                </PRIcon>
              </button>
              <button
                onClick={() => {
                  this.onPageChange(this.state.currentPage - 1);
                }}
              >
                <PRIcon className="prev">
                  <ArrowIcon />
                </PRIcon>
              </button>
            </div>
          }
          <div className="page-number-container">
            {pages.map((page, $index) => {
              let className =
                "page-number" +
                (page.current ? " current" : "") +
                (page.disabled ? " disabled" : "");
              return (
                <div key={$index} className={className}>
                  {page.disabled ? (
                    "..."
                  ) : (
                      <button
                        className="page-number"
                        onClick={() => {
                          this.onPageChange(page.pageNumber);
                        }}
                      >
                        {this.getPagenumber(page.pageNumber)}
                      </button>
                    )}
                </div>
              );
            })}
          </div>
          {
            <div className="next-icon-container">
              <button
                onClick={() => {
                  this.onPageChange(this.state.currentPage + 1);
                }}
              >
                <PRIcon className="next">
                  <ArrowIcon />
                </PRIcon>
              </button>
              <button
                onClick={() => {
                  this.onPageChange(totalPages);
                }}
              >
                <PRIcon className="next">
                  <ArrowIcon />
                </PRIcon>
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default withWidth()(PRPagination);
