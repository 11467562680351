import React from "react";
import PRTextField from "../PageContent/Form/Fields/PRTextField/PRTextField";
import { FileSelection } from "./FileSelection/FileSelection";
import { ContactField } from "../PageContent/Form/Fields/Contact/Contact";
import Constants from "../constants";
import PRButton from "../PageContent/PRButton/PRButton";
import { canUseDOM } from "fbjs/lib/ExecutionEnvironment";
import "./AdminPressRelease.scss";
import { PressRoomApi } from "../../services/pressRoomApi";
import moment from 'moment';
import FileDrop from "../PageContent/Form/Fields/FileDrop/FileDrop";
import { Categories } from '../Categories/Categories';
import PRCheckbox from "../PageContent/Form/Fields/PRCheckbox/PRCheckbox";
import { IPressRelease } from "../../interfaces/press-release";
import { DateTime } from "../PageContent/Date/DateTime";
import utils from "../../services/utils";

interface IAdminPressReleaseState {
  pressRelease: IPressRelease;
  changesSaved: boolean;
  loading: boolean;
  invalid: boolean;
  pressKitId: string;
  saving: boolean;
}

export default class AdminPressRelease extends React.Component<any, IAdminPressReleaseState> {
  private formEl: HTMLFormElement = null;
  private timestamp: Date = null;

  constructor(props: any) {
    super(props);

    this.state = {
      pressRelease: {
        Id: null,
        Images: [],
        Videos: [],
        Body: "",
        Title: "",
        ShortDescription: "",
        HomePageBanner: false,
        Contact: null,
        ThumbnailImage: null,
        CategoryId: null,
        Banner: null
      },
      changesSaved: true,
      loading: true,
      invalid: false,
      pressKitId: "",
      saving: false
    };
  }

  componentDidMount() {
    this.getPressRelease();
  }

  getPressRelease = async () => {
    let pressReleaseId = "";
    let pressKitId = "";
    var queryStringItems = document.location.search.substr(1).split("&");
    queryStringItems.forEach(item => {
      let keyValue = item.split("=");
      if (keyValue.length > 1) {
        if (keyValue[0] === "id") {
          pressReleaseId = keyValue[1];
        }
        if (keyValue[0] === "pressKitId") {
          pressKitId = keyValue[1];
        }
      }
    });

    if (!pressReleaseId && !pressKitId) {
      // there is nothing to load 
      this.setState({ loading: false });
      return;
    }

    if (pressReleaseId) {
      this.setState({ loading: true });
      let api = new PressRoomApi();
      let result = await api.getPressRelease(pressReleaseId);
      if (result.success) {
        this.setState({ pressRelease: result.data, loading: false, pressKitId: pressKitId });
      }
      return;
    }

    if (pressKitId) {
      let pressRelease = { ... this.state.pressRelease };
      pressRelease.RestrictedToPressKitId = pressKitId;
      this.setState({ pressRelease: pressRelease, loading: false, pressKitId: pressKitId });
    }
  }

  onDataChange = (data, name) => {
    let pressRelease = { ...this.state.pressRelease };
    pressRelease[name] = data;
    this.setState({ pressRelease: pressRelease, changesSaved: false });
  };

  getCKEditor4 = () => {
    let CKEditor = require('ckeditor4-react');
    return <CKEditor
      config={{
        toolbar: [["Source", "-", "Format", "Bold", "Italic", "Underline", "Superscript", "-", "BulletedList", "NumberedList", "Table", "Link", "Undo", "Redo"]],
        removeButtons: ""
      }}
      data={this.state.pressRelease.Body}
      onChange={(event, editor) => {
        this.onDataChange(event.editor.getData(), "Body");
      }}
    />;
  };

  isValid = () => {
    let valid = !utils.guidIsEmpty(this.state.pressRelease.CategoryId) &&
      this.state.pressRelease.ThumbnailImage &&
      this.state.pressRelease.Banner &&
      !utils.guidIsEmpty(this.state.pressRelease.Contact) &&
      this.formEl.checkValidity();
    this.setState({ invalid: !valid });
    return valid;
  }

  onSavePressRelease = async () => {
    if (this.state.saving) {
      return;
    }

    // Publish date time must be in the future
    if (this.state.pressRelease.PublishAt && moment().isAfter(this.state.pressRelease.PublishAt)) {
      return;
    }

    // This field has to be validated on save otherwise it will fail the validation in the backend.
    // All other fields are only validated on publish still.
    if (this.state.pressRelease.ShortDescription && this.state.pressRelease.ShortDescription.length > 85) {
      return;
    }

    this.setState({ saving: true });
    let api = new PressRoomApi();
    let result = await api.savePressRelease(this.state.pressRelease);
    if (result.success) {
      let pr = { ...this.state.pressRelease };
      pr.Id = result.data;
      pr.Published = false;
      this.timestamp = new Date();
      this.setState({ changesSaved: true, pressRelease: pr, saving: false });
    }
  };

  onSavePressReleaseForPressKit = async () => {
    if (this.state.saving) {
      return;
    }
    if (this.isValid()) {
      this.setState({ saving: true });
      let api = new PressRoomApi();
      let result = await api.savePressRelease(this.state.pressRelease);
      if (result.success) {
        this.goBack();
      }
    }
  };

  onPublishPressRelease = async () => {
    if (this.state.saving) {
      return;
    }
    if (this.state.changesSaved && this.state.pressRelease.Id) {
      if (this.isValid()) {
        this.setState({ saving: true });
        let api = new PressRoomApi();
        await api.publishPressRelease(this.state.pressRelease.Id);
        this.goBack();
      }
    }
  };

  cancel = () => {
    this.goBack();
  }

  goBack = () => {
    if (this.state.pressKitId) {
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        Constants.Links.PressKitForm +
        "?id=" +
        this.state.pressKitId;
    }
    else {
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        Constants.Links.PressReleaseDashboard;
    }
  }

  render() {
    return (
      <div className="admin-panel-container">
        <div>
          {this.state.pressRelease.RestrictedToPressKitId ?
            <div className="panel-header">
              <h2>Press Release</h2>
              <button onClick={this.onSavePressReleaseForPressKit} disabled={this.state.changesSaved}>Save</button>
              <button onClick={this.cancel}>Cancel</button>
              {this.timestamp && <small>Saved {moment(this.timestamp).format(Constants.TimeStampFormat)}</small>}
            </div> :
            <div className="panel-header">
              <h2>Press Release</h2>
              <div className="publish-buttons">
                <button onClick={this.onSavePressRelease} disabled={this.state.changesSaved}>Save Draft</button>
                <button onClick={this.onPublishPressRelease} disabled={!this.state.changesSaved || !this.state.pressRelease.Id || this.state.pressRelease.Published || !!this.state.pressRelease.PublishAt}>Publish</button>
                <button onClick={this.cancel}>{this.state.changesSaved ? 'Back' : 'Cancel'}</button>
                <div className="publish-status">
                  {this.timestamp && <div>Saved {moment(this.timestamp).format(Constants.TimeStampFormat)}</div>}
                  {this.state.changesSaved && this.state.pressRelease.Id && !this.state.pressRelease.Published && !!this.state.pressRelease.PublishAt &&
                    <div>Will be published at {moment(this.state.pressRelease.PublishAt).format(Constants.TimeStampFormat)}</div>}
                </div>
                {
                  this.state.pressRelease.Id != null && (
                    <PRButton
                      data={{ type: "priamary", link: this.state.pressRelease.Id != null ? window.location.protocol + "//" + window.location.host + Constants.Links.PressReleasePreview + this.state.pressRelease.Id.toLowerCase().replace("{", "").replace("}", "") : "", newtab: true }}>
                      Preview Draft</PRButton>
                  )}
              </div>
            </div>
          }

          {!this.state.loading && (
            <div className="admin-panel-inner-container" id="ignorePDF">
              <form ref={form => (this.formEl = form)} noValidate>
                {
                  !this.state.pressRelease.RestrictedToPressKitId &&
                  <div className="input-container">
                    <DateTime
                      label="Publish at"
                      onChange={data => {
                        this.onDataChange(data, "PublishAt");
                      }}
                      selectedDateTime={this.state.pressRelease.PublishAt}
                      clearable={true}
                      disablePast={true}
                    />
                  </div>
                }
                <div className="input-container">
                  <PRTextField
                    required
                    label="Title"
                    value={this.state.pressRelease.Title}
                    onChange={data => {
                      this.onDataChange(data, "Title");
                    }}
                    error={this.state.invalid && !this.state.pressRelease.Title}
                  />
                </div>
                <div className="input-container">
                  <PRTextField
                    maxLength="85"
                    label="Short description"
                    value={this.state.pressRelease.ShortDescription}
                    onChange={data => {
                      this.onDataChange(data, "ShortDescription");
                    }}
                    error={(this.state.invalid && !this.state.pressRelease.ShortDescription) || (this.state.pressRelease.ShortDescription && this.state.pressRelease.ShortDescription.length > 85)}
                  />
                </div>
                <div className="input-container">
                  <PRCheckbox
                    label="Display on Homepage carousel"
                    checked={this.state.pressRelease.HomePageBanner}
                    onChange={(value: boolean) => { this.onDataChange(value, 'HomePageBanner'); }}
                    options={{ theme: "outline" }}>
                  </PRCheckbox>
                </div>
                <div className="input-container">
                  <Categories
                    required
                    onChange={data => {
                      this.onDataChange(data, "CategoryId");
                    }}
                    selectedCategory={this.state.pressRelease.CategoryId}
                    error={this.state.invalid && utils.guidIsEmpty(this.state.pressRelease.CategoryId)}
                    canBeCleared>
                  </Categories>
                </div>
                <div className="input-container custom-input">
                  <label>Press Release Description</label>
                  {canUseDOM && this.getCKEditor4()}
                </div>

                <div className="input-container file-selection">
                  <label>Images</label>
                  <FileSelection
                    fileType="Image"
                    label="Image"
                    multipleSelection={true}
                    selectedItems={this.state.pressRelease.Images}
                    onChange={files => {
                      this.onDataChange(files, "Images");
                    }}
                  >
                    <PRButton data={{ type: "primary" }} className="">
                      Select images
                  </PRButton>
                  </FileSelection>

                  <div className="preview-container">
                    {this.state.pressRelease.Images.map((file, $index) => {
                      return (
                        <div className="image-preview" key={$index} style={{ backgroundImage: 'url(' + (file.Url || file.Thumbnail.ImageUrl) + ')' }}></div>
                      );
                    })}
                  </div>
                </div>

                <div className="input-container file-selection">
                  <label>Videos</label>
                  <FileSelection
                    fileType="Video"
                    label="Video"
                    multipleSelection={true}
                    selectedItems={this.state.pressRelease.Videos}
                    onChange={files => {
                      this.onDataChange(files, "Videos");
                    }}
                  >
                    <PRButton data={{ type: "primary" }} className="">
                      Select videos
                  </PRButton>
                  </FileSelection>

                  <div className="preview-container">
                    {this.state.pressRelease.Videos.map((file, $index) => {
                      return (
                        <div className="image-preview" key={$index} style={{ backgroundImage: 'url(' + (file.Url || file.Thumbnail.ImageUrl) + ')' }}></div>
                      );
                    })}
                  </div>
                </div>

                <ContactField
                  required
                  selectedContactId={this.state.pressRelease.Contact}
                  onChange={data => { this.onDataChange(data ? data.Id : null, "Contact"); }}
                  error={this.state.invalid && utils.guidIsEmpty(this.state.pressRelease.Contact)}>
                </ContactField>

                <div className="input-container banner">
                  <FileDrop
                    required
                    type="image"
                    label={"Banner Image: " + Constants.ImageDimensions.Banner.width + "x" + Constants.ImageDimensions.Banner.height}
                    value={this.state.pressRelease && this.state.pressRelease.Banner ? this.state.pressRelease.Banner.ImageUrl : null}
                    onChange={(data) => { this.onDataChange(data, 'Banner') }}
                    error={this.state.invalid && !this.state.pressRelease.Banner}>
                  </FileDrop>
                </div>

                <div className="input-container thumbnail">
                  <FileDrop
                    required
                    type="image"
                    label={"Thumbnail Image: " + Constants.ImageDimensions.Thumbnail.width + "x" + Constants.ImageDimensions.Thumbnail.height}
                    value={this.state.pressRelease && this.state.pressRelease.ThumbnailImage ? this.state.pressRelease.ThumbnailImage.ImageUrl : null}
                    onChange={(data) => { this.onDataChange(data, 'ThumbnailImage') }}
                    error={this.state.invalid && !this.state.pressRelease.ThumbnailImage}>
                  </FileDrop>
                </div>
              </form>
            </div>
          )}

          {this.state.pressRelease.RestrictedToPressKitId ?
            <div className="panel-footer">
              <button onClick={this.onSavePressReleaseForPressKit} disabled={this.state.changesSaved}>Save</button>
              <button onClick={this.cancel}>Cancel</button>
              {this.timestamp && <small>Saved {moment(this.timestamp).format(Constants.TimeStampFormat)}</small>}
            </div> :
            <div className="panel-footer">
              <div className="publish-buttons">
                <button onClick={this.onSavePressRelease} disabled={this.state.changesSaved}>Save Draft</button>
                <button onClick={this.onPublishPressRelease} disabled={!this.state.changesSaved || !this.state.pressRelease.Id || this.state.pressRelease.Published || !!this.state.pressRelease.PublishAt}>Publish</button>
                <button onClick={this.cancel}>{this.state.changesSaved ? 'Back' : 'Cancel'}</button>
                <div className="publish-status">
                  {this.timestamp && <div>Saved {moment(this.timestamp).format(Constants.TimeStampFormat)}</div>}
                  {this.state.changesSaved && this.state.pressRelease.Id && !this.state.pressRelease.Published && !!this.state.pressRelease.PublishAt &&
                    <div>Will be published at {moment(this.state.pressRelease.PublishAt).format(Constants.TimeStampFormat)}</div>}
                </div>
                {
                  this.state.pressRelease.Id != null && (
                    <PRButton
                      data={{ type: "priamary", link: this.state.pressRelease.Id != null ? window.location.protocol + "//" + window.location.host + Constants.Links.PressReleasePreview + this.state.pressRelease.Id.toLowerCase().replace("{", "").replace("}", "") : "", newtab: true }}>
                     Preview Draft</PRButton>
                  )}
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
