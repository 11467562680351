import React from 'react';
import PRLabel from "../PRLabel/PRLabel";

export interface PRLinkProps {
    data: {
        Url:string;
        Label: string;
        Icon:string;
    },
    isSitecore?:boolean;
}

export default class PRLink extends React.Component<PRLinkProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container">
                <a href={this.props.data.Url}><span dangerouslySetInnerHTML={{__html: this.props.data.Label}}></span></a>
            </div>
        );
    }
}