import React from "react";
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default class Placeholder extends React.Component {
    static defaultProps = {
        placeholder: null,
        isSitecore: true,
        isEditing: false,
        placeholderKey: 'not set',
        isDynamic: false
    }
    render() {
        let self = this;

        // If we have a props object called "placeholder" this is supplied by 
        // Sitecore so return the value in the placeholder key and ignore 
        // any of the static front end code in the file.

        if (this.props.hasOwnProperty('placeholder') && this.props.placeholder != null) {
            return this.renderSitecore();
        }

        return this.renderFED();
    }
    componentDidMount(){
        if(this.node) {
            var arr = this.node.getElementsByTagName('script')
                for (var n = 0; n < arr.length; n++)
                    eval(arr[n].innerHTML)
        }

    }
    renderSitecore() {
        let self = this;
        if (this.props.isDynamic) {
            
            return (
                <div  ref={node => this.node = node} dangerouslySetInnerHTML={{ __html: this.props.placeholder.$Id[this.props.placeholderKey] }}></div>
            )
        }

        return (
            <div  ref={node => this.node = node} dangerouslySetInnerHTML={{ __html: this.props.placeholder[this.props.placeholderKey] }}></div>
        );
    }
    renderFED() {
        let self = this;

        if (this.props.content) {
            let content = [];
            let fallbackToAll = true;

            if (typeof (this.props.content) === 'string') {
                return (<span>{this.props.content}</span>);
            }

            if (React.Children.count(this.props.content) > 0) {
                content = React.Children.map(this.props.content, (child, i) => {
                    if (child.props.hasOwnProperty('placeholderKey')) {
                        if (child.props.placeholderKey == self.props.placeholderKey) {
                            return child;
                        }
                    }
                });
            }

            if (content.length > 0) {
                return (
                    <div>{content}</div>
                );
            }

            return (<h2>No Content</h2>);
        }
        if (this.props.children) {
            let content = [];
            if (React.Children.count(this.props.children) > 0) {
                content = React.Children.map(this.props.children, (child, i) => {
                    if (child.props.hasOwnProperty('placeholderKey')) {
                        if (child.props.placeholderKey == self.props.placeholderKey) {
                            return child;
                        }
                    }
                });
            }

            if (content.length > 0) {
                return (
                    <div>{content}</div>
                );
            }

            return this.props.children;
        }

        return (
            <h2 style={{display: 'none'}}>No content </h2>
        )
    }
}