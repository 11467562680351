import React from 'react';
import "./PRContactCardList.scss";
import PRContactCard from '../PRContactCard/PRContactCard';

export interface PRContactCardListProps {
    data?: {
        Items?: Array<{
            HeaderTitle: string;
            Image: string;
            ContactName: string;
            ContactDescription: string;
            Email: string;
            PhoneNumber: string;
        }>;
    };
    isSitecore?: boolean;
}

export default class PRContactCardList extends React.Component<PRContactCardListProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        let contactList = [];
        if (this.props.isSitecore) {
            this.props.data.Items.map((child, $index) => {
                contactList.push(<PRContactCard data={child} key={`card-${$index}`}></PRContactCard>);
            });

        }
        else {
            React.Children.map(this.props.children, (child, $index) => {
                contactList.push(child);
            });
        }
        return (
            <div className="pr-contact-card-list">
                <div className="pr-contact-card-list-container">
                    {contactList}
                </div>
            </div>
        );
    }
}