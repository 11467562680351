export class FilterHelper {
    addToUrlString = (urlString: Array<string>, key: string, value: any) => {
        key = encodeURI(key);
        value = value ? encodeURI(value) : null;

        let kvp = urlString;
        let indexOfItem = -1;
        kvp.forEach((item, $index) => {
            if (item.split('=')[0] === key) {
                indexOfItem = $index;
            }
        });

        if (indexOfItem !== -1) {
            if (value) {
                kvp[indexOfItem] = [key, value].join("=");
            }
            else {
                kvp.splice(indexOfItem, 1);
            }
        }
        else {
            if (value) {
                kvp[kvp.length] = [key, value].join("=");
            }
        }

        return kvp;
    };

    applyUrlStringToUrl = (urlString: Array<string>, filters: any) => {
        window.history.pushState(
          filters,
          "new filter",
          "?" + urlString.join("&")
        );
      };
}