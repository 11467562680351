import React from "react";
import "./ItemType.scss";
import { PRIcon } from "../../PRIcon/PRIcon";
import { ImageIcon, VideoIcon, DocumentIcon, BookIcon } from "../../PRIcon/Icons";

interface IItemTypeProps {
  type: string;
}

export class ItemType extends React.Component<IItemTypeProps> {
  render() {
    return (
      <PRIcon className="item-type">
        {this.props.type === "Image" && <ImageIcon />}
        {this.props.type === "Video" && <VideoIcon />}
        {this.props.type === "Press Release" && <DocumentIcon />}
        {this.props.type === "Press Kit" && <BookIcon />}
      </PRIcon>
    );
  }
}