import React from 'react';

export interface PRComplexTabProps {
    Label?: string;
}

export default class PRComplexTab extends React.Component<PRComplexTabProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="complex-tab">
                {this.props.children}
            </div>
        );
    }
}