import React from 'react';
import Placeholder from "../../../js/extensions/Placeholder";
import './scaffolding.scss';

export interface PR2ColumnProps {
    placeholder?: boolean;
}

export default class PR2Column50 extends React.Component<PR2ColumnProps, {}> {

    render() {

        return (
            <div className="PRSplitLayout layout-50">
                <div className="container">
                    <div className="row">
                        <div className="leftGutter">
                            <Placeholder placeholderKey={'tp-left'} isDynamic={true} placeholder={this.props.placeholder}>
                                {this.props.children}
                            </Placeholder>
                        </div>
                        <div className="rightGutter">
                            <Placeholder placeholderKey={'tp-right'} isDynamic={true} placeholder={this.props.placeholder}>
                                {this.props.children}
                            </Placeholder>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}