import React from "react";
import "./PRMediaCardList.scss";
import PRButton from "../../Pagecontent/PRButton/PRButton";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import PRCarousel from "../../Pagecontent/PRCarousel/PRCarousel";
import SearchResults from "../../SearchResults/SearchResults";
import Card from "../../PageContent/Card/Card";
import { ItemType } from "../../PageContent/ItemType/ItemType";
import { CookieHelper } from "../../../services/cookieHelper";
import { Notification } from "../../Notification/Notification";

interface PRMediaCardListProps {
  width: string;
  data?: {
    Header: string;
    Type: string;
    CardList?: Array<{
      Id?: string;
      Image?: string;
      Date?: string;
      Title?: string;
      Copy?: string;
      LinkUrl?: string;
      Type?: string;
      VideoUrl?: string;
    }>;
  };
}

interface PRMediaCardListState {
  showNotification: boolean;
}

class PRMediaCardList extends React.Component<PRMediaCardListProps, PRMediaCardListState> {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: false
    };
  }

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  getCardList() {
    if (!this.props.data.CardList || !this.props.data.CardList.length) {
      return (
        <p>No {this.props.data.Type}s</p>
      );
    }

    if (this.props.width === "xs") {
      let items = [];
      this.props.data.CardList.map((child, $index) => {
        items.push(<Card key={"cardlist-item-" + $index} data={child} actions={{ addToCart: true, download: true }} />)
      });
      return (
        <PRCarousel data={{ Items: items }} />
      );
    } else {
      return (
        <SearchResults
          items={this.props.data.CardList}
          actions={{ addToCart: true, download: true }}
          includePreview={this.props.data.Type === "Image" || this.props.data.Type === "Video"}>
        </SearchResults>
      );
    }
  }

  render() {
    return (
      <div className="pr-media-card-list" id="pr-media-card-list">
        {this.props.data && this.props.data.Header && (
          <div className="pr-card-list-header-container">
            <div>
              <ItemType type={this.props.data.Type} />
              <h2>{this.props.data.Header}</h2>
            </div>
            <div>
              <Notification key="add-to-cart-notification"
                message="You added these items to your basket"
                open={this.state.showNotification}
                onClose={this.handleNotificationClose} />
              <PRButton data={{ type: "tertiary" }} onClick={this.addAllToCart}>Add all to cart</PRButton>
            </div>
          </div>
        )}
        {this.getCardList()}
      </div>
    );
  }

  addAllToCart = () => {
    let itemsToAdd = this.props.data.CardList.map((item) => {
      return { id: item.Id, title: item.Title, type: item.Type };
    });

    if (itemsToAdd.length) {
      let cookieHelper = new CookieHelper();
      let cookie = cookieHelper.getCookie("prcart");
      let items: Array<any> = cookie ? JSON.parse(cookie) : [];
      itemsToAdd.forEach(itemToAdd => {
        if (items.filter(item => item.id === itemToAdd.id).length === 0) {
          items.push(itemToAdd);
        }
      });
      cookieHelper.setCookie("prcart", items);

      this.setState({ showNotification: true });
    }
  };

  handleNotificationClose = () => {
    this.setState({ showNotification: false });
  };
}

export default withWidth()(PRMediaCardList);
