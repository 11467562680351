import React from "react";

export interface PRCarouselItemProps {
  data: {
    Image: string;
    Label: string;
    Url?: string;
  };
}

export default class PRCarouselItem extends React.Component<
  PRCarouselItemProps,
  {}
> {
  constructor(props) {
    super(props);
    // this.animating = false;
  }

  render() {
    return (
      <div className="pr-slide-container">
        <div className="pr-slide">
          <a href={this.props.data.Url}>
            <img src={this.props.data.Image} />
            <div className="pr-slide-text">
              <h3>{this.props.data.Label}</h3>
            </div>
          </a>
        </div>
      </div>
    );
  }
}
