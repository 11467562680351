import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { PRIcon } from "../../PRIcon/PRIcon";
import withWidth from "@material-ui/core/withWidth";
import "./PRDialog.scss";
import { CloseIcon } from "../../PRIcon/Icons";

interface IPRDialogProps {
  open: boolean;
  onClose: Function;
  width?: string;
  className?: string;
  maxWidth?: any;
  fullWidth?: boolean;
}

interface IPRDialogState {
  openDialog: boolean;
}

class PRDialog extends React.Component<IPRDialogProps, IPRDialogState> {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: this.props.open
    };
  }

  public static defaultProps: Partial<IPRDialogProps> = {
    maxWidth: "sm",
    fullWidth: true
  };

  handleClose = () => {
    this.setState({ openDialog: false });
    this.props.onClose();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ openDialog: this.props.open });
    }
  }

  getComponent = key => {
    return React.Children.map(this.props.children, child => {
      if ((child as any).key === key) {
        return child;
      }
    });
  };

  render() {
    let dialogClass =
      "pr-dialog " + this.props.width + " " + this.props.className;
    return (
      <Dialog
        open={this.state.openDialog}
        onClose={this.handleClose}
        className={dialogClass}
        maxWidth={this.props.maxWidth}
        fullWidth={this.props.fullWidth}
        classes={{paper: "dialog-scroll-paper", paperWidthSm: "dialog-scroll-paper-sm", paperWidthMd: "dialog-scroll-paper-md"}}
      >
        <div className="dialog-container">
          <div className="dialog-header">
            <div className="close-container" onClick={this.handleClose}>
              <PRIcon>
                <CloseIcon />
              </PRIcon>
            </div>
            <div className="dynamic-header-content">
              {this.getComponent("header")}
            </div>
          </div>

          <div className="dialog-content">
            <div className="dialog-content-container">
              {this.getComponent("content")}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withWidth()(PRDialog);
