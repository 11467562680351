import React from "react";
import Input from "@material-ui/core/Input";
import "./PRMenuSearch.scss";
import { PRIcon } from "../../PRIcon/PRIcon";
import { SearchIcon } from "../../PRIcon/Icons";

interface IPRMenuSearchProps {
  data: {
    SearchUrl: string;
  };
}

interface IPRMenuSearchState {
  value: string;
}

export default class PRMenuSearch extends React.Component<IPRMenuSearchProps, IPRMenuSearchState> {
  constructor(props) {
    super(props);

    this.state = {
      value: ""
    };
  }

  onKeyUp = e => {
    if (e.key === "Enter") {
      this.goToRedirectUrl(e.target.value);
    }
  };

  goToRedirectUrl = value => {
    window.location.href =
      window.location.protocol +
      "//" +
      window.location.host +
      this.props.data.SearchUrl + 
      "?Search=1" +
      (value ? "&Query=" + value : "");
  };

  onChange = e => {
    this.setState({ value: e.target.value });
  };

  onSearchClick = () => {
    this.goToRedirectUrl(this.state.value);
  };

  render() {
    return (
      <div className="other-item col-sm-5 search-item">
        <Input
          id="search"
          type="search"
          className="search-field"
          placeholder="Search"
          onKeyUp={this.onKeyUp}
          onChange={this.onChange}
          classes={{ underline: "input-underline" }}
        />
        <div onClick={this.onSearchClick}>
          <PRIcon className="search-icon">
            <SearchIcon />
          </PRIcon>
        </div>
      </div>
    );
  }
}
