export class RequestHandler {
    root: string = "";

    constructor(root: string | undefined) {
        if (root) {
            this.root = root;
        } else {
            throw "Request Handler root path not initialized";
        }

    }

    private request = (method: string, resource: string, data?: any): Promise<any> => {
        let self = this;
        if (typeof data !== "string") {
            data = JSON.stringify(data);
        }
        return new Promise(function (fullfill, reject) {
            let xhr = new XMLHttpRequest();
            let url = self.root + resource;
            xhr.open(method, url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        fullfill(xhr.responseText);
                    } else {
                        reject(xhr.responseText);
                    }
                }
            };
            if (data) {
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.send(data);
            } else {
                xhr.send();
            }
        })
    };

    private requestAsForm = (method: string, resource: string, data?: any): Promise<any> => {
        let self = this;
        return new Promise(function (fullfill, reject) {
            let xhr = new XMLHttpRequest();
            let url = self.root + resource;
            xhr.open(method, url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        fullfill(xhr.responseText);
                    } else {
                        reject(xhr.responseText);
                    }
                }
            };
            if (data) {
                xhr.send(data);
            } else {
                xhr.send();
            }
        })
    };

    async get(resource: string): Promise<any> {
        let result: any = {};
        try {
            let response = await this.request("GET", resource);
            result["data"] = JSON.parse(response);
            result["success"] = true;
            return result;
        }
        catch{
            result = {
                success: false,
                message: "GET request failed",
                data: null
            };
            return result;
        }
    }
    async post(resource: string, data: string): Promise<any> {
        let result: any = {};
        try {
            let response = await this.request("POST", resource, data);
            if (response) {
                result["data"] = JSON.parse(response);
            }
            result["success"] = true;
            return result;
        }
        catch (err) {
            result = {
                success: false,
                message: "POST request failed",
                data: null
            };
            return result;
        }
    }
    async postAsForm(resource: string, data: any): Promise<any> {
        let result: any = {};
        try {
            let response = await this.requestAsForm("POST", resource, data);
            if (response) {
                result["data"] = JSON.parse(response);
            }
            result["success"] = true;
            return result;
        }
        catch{
            result = {
                success: false,
                message: "POST request failed",
                data: null
            };
            return result;
        }
    }
    async delete(resource: string): Promise<any> {
        let result: any = {};
        try {
            let response = await this.request("DELETE", resource);
            if (response) {
                result["data"] = JSON.parse(response);
            }
            result["success"] = true;
            return result;
        }
        catch{
            result = {
                success: false,
                message: "DELETE request failed",
                data: null
            };
            return result;
        }
    }
}