import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import PRPagination from '../../PageContent/PRPagination/PRPagination';
import { PressRoomApi } from '../../../services/pressRoomApi';
import SearchResults from '../../SearchResults/SearchResults';

interface IMediaSearchProps {
    data: {
        NoResultsMessage: string;
        Filters: {
            Query: string;
            SortType: string;
            DateFrom: string;
            DateTo: string;
            Page: number;
            PerPage: number;
            CategoryId: string;
            Type: Array<string>;
        }
    };
}

class MediaSearch extends React.Component<IMediaSearchProps, {}> {
    constructor(props) {
        super(props);
    }

    state = {
        items: [],
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 10
    }

    componentDidMount() {
        this.queryAPI(this.props.data.Filters);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data.Filters) !== JSON.stringify(this.props.data.Filters)) {
            this.queryAPI(this.props.data.Filters);
        }
    }

    queryAPI = async (filters) => {
        if (filters) {
            let api = new PressRoomApi();
            let response = await api.searchMedia(filters);
            if (response.success) {
                this.setState({ items: response.data.Results, totalItems: response.data.TotalCount, itemsPerPage: filters.PerPage });
            }
        }
    }

    onPageChange = (currentPage) => {
        let filters = { ...this.props.data.Filters };
        filters.Page = currentPage;
        this.queryAPI(filters);
    }

    render() {
        return (
            <React.Fragment>
                <SearchResults
                    items={this.state.items}
                    includePreview={this.props.data.Filters.Type.length === 1 && (this.props.data.Filters.Type[0] === "Image" || this.props.data.Filters.Type[0] === "Video")}
                    actions={{ addToCart: true, download: true }}
                    noResultsMessage={this.props.data.NoResultsMessage}
                />
                <PRPagination
                    onPageChange={this.onPageChange}
                    data={{ current: this.state.currentPage, totalItems: this.state.totalItems, itemsPerPage: this.state.itemsPerPage }}>
                </PRPagination>
            </React.Fragment>
        );
    }
}

export default withWidth()(MediaSearch);